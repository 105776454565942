import { createContext, useContext, useEffect, useState, memo } from "react";
import { auth, secondaryAuth } from "../utils/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  // updateCurrentUser,
  // updatePassword,
  // updatePhoneNumber,
  updateProfile,
  getAuth,
  sendEmailVerification,
  updateEmail,
  updatePassword,
  // getUserByEmail,
  // applyActionCode,
  // updatePhoneNumber,
  // PhoneAuthProvider,
  // RecaptchaVerifier,
  // FirebaseUser,
} from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import UsersService from "../services/Users.Service";
// import { useSelector, useDispatch } from "react-redux";
// import PortaisService from "../services/portais.service";
import { getUrlDetails } from "../utils/UrlDomainUtil";
import { GetPortal } from "../utils/urlPortalUtil";
import GenericService from "../services/Generic.service";

const AuthContext = createContext({
  currentUser: null,
  domainDetails: null,
  changePortal: () => Promise,
  register: () => Promise,
  login: () => Promise,
  logout: () => Promise,
  existEmail: () => Promise,
  changePassword: () => Promise,
  changeEmail: () => Promise,
  changeName: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

function AuthContextProviderComponent({ children }) {
  // const FirebaseUser = auth.currentUser;

  const [domainDetails, setDomainDetails] = useState(null);

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (domainDetails === null) {
      getUrlDetails().then((urlDetails) => {
        setDomainDetails(urlDetails);
        // //console.log("AuthContext -> urlDetails=", urlDetails);
      });
    }
  }, []);

  useEffect(() => {
    // //console.log("auth=", auth);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      try {
        if (user !== null) {
          if (user.uid) {
            // //console.log("user=", user);
            // if (!user.emailVerified) {
            //   // reSendEmailVerification(user);
            //   // const actionCodeSettings = {
            //   //   continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
            //   //   url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
            //   //   handleCodeInApp: false,
            //   // };
            //   // // //console.log("actionCodeSettings=", actionCodeSettings);
            //   // //console.log("Email será enviado!!!");
            //   // sendEmailVerification(user, actionCodeSettings)
            //   //   .then(() => {
            //   //     //console.log("Email enviado com sucesso!!!");
            //   //   })
            //   //   .catch((error) => {
            //   //     //console.log(error);
            //   //   });

            //   //console.log("Email1 enviado!!!");
            // } else {
            //   // const actionCodeSettings = {
            //   //   continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
            //   //   url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
            //   //   handleCodeInApp: false,
            //   // };
            //   // //console.log("actionCodeSettings=", actionCodeSettings);
            //   // sendEmailVerification(user.user, actionCodeSettings)
            //   //   .then(() => {
            //   //     //console.log("Email2 enviado com sucesso!!!");
            //   //   })
            //   //   .catch((error) => {
            //   //     //console.log(error);
            //   //   });

            //   //console.log("Email2 enviado!!!");
            // }
            getUrlDetails().then((urlDetails) => {
              // if (!isNaN(urlDetails) && urlDetails !== null) {
              if (urlDetails) {
                // //console.log("continueUrl=", window.location.protocol + "//" + urlDetails.domain + "/signup-validation");
                setDomainDetails(urlDetails);

                let currentID = user.uid;
                // if (process.env.REACT_APP_ENV_SPEC_NAME === "ambienteDevelopment") {
                //   if ( urlDetails.domainId === "VxZvnWdSC6x9RVOQ2D5L" && currentID === "TRhRghIYvkTs5igtOt6YnoQGAvC3") {
                //     currentID = "9Pu1YD3DXPO2LXgBrHriSNmkA6k1";
                //     // currentID = "umH5n52e5FrJipnKEVmF";
                //   }
                //   // currentID = "SV9S8LUnAcdNJeWzV3X60lEgpQq2"
                // }
                // if ( 
                //   // urlDetails.domainId === "4X8eTXc3fCjlGFQPJ3gi" && 
                //   currentID === "TRhRghIYvkTs5igtOt6YnoQGAvC3") {
                //   // currentID = "hiiXhLb5yzZwWFwQ2hZB";
                //   currentID = "lzNlMUVOX9UlW8ikwtWHBxLJNSk2";
                //   currentID = "2d7cufLQpNfT32ahov0fnpffFyo1";
                // }

                if (
                  //   // urlDetails.domainId === "4X8eTXc3fCjlGFQPJ3gi" && 
                  //   // urlDetails.domainId === "4sy7BRerrFlj1emAaeMd" &&
                  currentID === "TRhRghIYvkTs5igtOt6YnoQGAvC3") {
                  // currentID = "2d7cufLQpNfT32ahov0fnpffFyo1";
                  // currentID = "SV9S8LUnAcdNJeWzV3X60lEgpQq2";
                  // currentID = "J9oBpbsrFNUHwgkvFNj1dspIVCo1";
                  // currentID = "rEfbj3P4ORa4VMAswiLtgV0eAWB3";
                  // currentID = "kdX7XOgQsWQqUBhSO5ngb902b8N2";
                  // currentID = "S4Vm5Isi7tTU0YhNv5edsBZqidn2";
                  // currentID = "YD8TsfiRU9elBaR4lajhBO8Eghy2";
                }

                // console.log("currentID=", currentID);
                // UsersService.getRulesOwnedUnderPortalID(user.uid, urlDetails.domainId).then((rulesOwnedRetrieved) => {
                UsersService.getRulesOwnedUnderPortalID(currentID, urlDetails.domainId).then((rulesOwnedRetrieved) => {
                  // console.log("rulesOwnedRetrieved=", rulesOwnedRetrieved);
                  // console.log("user=", user);
                  // setCurrentUser({ ...user, rulesOwned: rulesOwnedRetrieved });
                  // setCurrentUser({ ...user, uid:currentID, rulesOwned: rulesOwnedRetrieved });
                  // console.log("authContext => Passo 1 => currentID=", currentID);
                  GenericService.getOne("praticantes", currentID).then((praticanteRetrieved) => {
                    if (praticanteRetrieved !== undefined && praticanteRetrieved !== null) {
                      if (praticanteRetrieved.dadosColaborador !== undefined
                        && praticanteRetrieved.dadosColaborador.administrativo !== undefined
                        && praticanteRetrieved.dadosColaborador.administrativo.idEmpregador !== undefined) {
                        setCurrentUser({ ...user, uid: currentID, rulesOwned: rulesOwnedRetrieved, idEmpregador: praticanteRetrieved.dadosColaborador.administrativo.idEmpregador, nomeEmpregador: praticanteRetrieved.dadosColaborador.administrativo.nomeEmpregador });
                      }
                      else {
                        setCurrentUser({ ...user, uid: currentID, rulesOwned: rulesOwnedRetrieved });
                      }
                    }
                    else {
                      setCurrentUser({ ...user, uid: currentID, rulesOwned: rulesOwnedRetrieved });
                    }
                  }).catch((error) => {
                    console.log("error=", error);
                    setCurrentUser({ ...user, uid: currentID, rulesOwned: rulesOwnedRetrieved });
                  });
                });
              } else {
                setCurrentUser(undefined);
                getUrlDetails().then((urlDetails) => {
                  setDomainDetails(urlDetails);
                });
              }
            });
          } else {
            setCurrentUser(undefined);
            getUrlDetails().then((urlDetails) => {
              setDomainDetails(urlDetails);
            });
          }
        } else {
          setCurrentUser(undefined);
          getUrlDetails().then((urlDetails) => {
            setDomainDetails(urlDetails);
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
    // //console.log("Passou aqui!");
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const changePortal = async (portalName) => {
    // //console.log("portalName=", portalName);
    // let newPortal = await getPortalDetails(portalName);
    let newPortal = await GetPortal();
    //console.log("newPortal=", newPortal);
    // setDomainDetails({ ...domainDetails, ...newPortal });
    setDomainDetails({
      domain: domainDetails.domain,
      domainId: domainDetails.domainId,
      subDomain: domainDetails.subDomain,
      portal: newPortal.urlportal,
      portalId: newPortal.Id,
    });
    // //console.log(
    //   {
    //     domain: domainDetails.domain,
    //     domainId: domainDetails.domainId,
    //     subDomain: domainDetails.subDomain,
    //     portal: newPortal.portal,
    //     portalId: newPortal.portalId,
    //   },
    //   "=",
    //   domainDetails
    // );
    // //console.log("domainDetails=", domainDetails);
    return true;
  };


  const existEmail = async (email) => {
    if (email === undefined || email === null || email === "") return false;
    // //console.log("getAuth().UsersService=", getAuth().UsersService);



    // return getAuth().UsersService.existEmail(email);
    // return await 
    // getAuth()
    // .getUserByEmail(email)
    // .then((userRecord) => {
    //   return userRecord !== undefined && userRecord !== null ? true : false;
    //   // See the UserRecord reference doc for the contents of userRecord.
    //   //console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
    // })
    // .catch((error) => {
    //   //console.log('Error fetching user data:', error);
    // });
    // return await UsersService.existEmail(email);
  };

  const changePassword = async (lastPassword, newPassword) => {
    // //console.log("changePassord() vai rodar!");
    const user = getAuth().currentUser;
    return signOut(auth)
      .then(async () => {
        return await signInWithEmailAndPassword(auth, user.email, lastPassword)
          .then(async (userCredential) => {
            return updatePassword(userCredential.user, newPassword)
              .then(async () => {
                // //console.log("changePassord() -> sucesso!");
                return true;
              })
              .catch((error) => {
                //console.log(error);
                return false;
              });
          })
          .catch((error) => {
            //console.log(error);
            return false;
          });
      })
      .catch((error) => {
        //console.log(error);
        return false;
      });
  };

  const changeEmail = async (lastPassword, newEmail) => {
    // //console.log("changeEmail() vai rodar!");
    const user = getAuth().currentUser;
    return signOut(auth)
      .then(async () => {
        return await signInWithEmailAndPassword(auth, user.email, lastPassword)
          .then(async (userCredential) => {
            if (userCredential) {
              // return updateEmail(userCredential.user, newEmail)
              //   .then(async () => {
              //     //console.log("changeEmail() -> sucesso!");
              return await UsersService.updateEmail(user.uid, newEmail)
                .then((result) => {
                  // if (result) reSendEmailVerification(userCredential);
                  return result;
                })
                .catch((error) => {
                  //console.log(error);
                  return false;
                });
              // })
              // .catch((error) => {
              //   //console.log(error);
              //   return false;
              // });
            }
          })
          .catch((error) => {
            //console.log(error);
            return false;
          });
      })
      .catch((error) => {
        //console.log(error);
        return false;
      });
  };

  const changeName = async (lastPassword, newName) => {
    // //console.log("changeEmail() vai rodar!");
    const user = getAuth().currentUser;
    return signOut(auth)
      .then(async () => {
        return await signInWithEmailAndPassword(auth, user.email, lastPassword)
          .then(async (userCredential) => {
            if (userCredential) {
              // if (user !== null) {
              //   user.providerData.forEach((profile) => {
              //     // //console.log("Sign-in provider: " + profile.providerId);
              //     // //console.log("  Provider-specific UID: " + profile.uid);
              //     // //console.log("  Name: " + profile.displayName);
              //     // //console.log("  Email: " + profile.email);
              //     // //console.log("  Photo URL: " + profile.photoURL);
              //     // //console.log("  profile: ", profile);

              //   });
              // }

              // return updateProfile(userCredential.user, { displayName: newName })
              //   .then(async () => {
              //     //console.log("changeEmail() -> sucesso!");
              return await UsersService.updateName(user.uid, newName)
                .then((result) => {
                  // if (result) reSendEmailVerification(userCredential);
                  return result;
                })
                .catch((error) => {
                  //console.log(error);
                  return false;
                });
              // })
              // .catch((error) => {
              //   //console.log(error);
              //   return false;
              // });
            }
          })
          .catch((error) => {
            //console.log(error);
            return false;
          });
      })
      .catch((error) => {
        //console.log(error);
        return false;
      });
  };

  const signup = async (userName, email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        let newUser = {
          dadosPessoais: {
            name: userName,
          },
          dadosContato: {
            email: email,
            celular: "",
          },
          metadados: {
            ownerid: user.user.uid ? user.user.uid : "",
            owner: userName ? userName : "",
            creation: Timestamp.fromDate(new Date()),
            date: Timestamp.fromDate(new Date()),
            version: 1,
            active: true,
          },
          usersgroupsowned: [],
        };
        // let newUser = {
        //   name: userName,
        //   email: email,
        //   metadataownerid: user.user.uid,
        //   metadataowner: userName,
        //   metadatacreation: Timestamp.fromDate(new Date()),
        //   metadatadate: Timestamp.fromDate(new Date()),
        //   metadataversion: 1,
        //   metadataactive: true,
        //   usersgroupsowned: [],
        // };
        await UsersService.createWithID(user.user.uid, newUser);
        //user.user.emailVerified = true;
        await updateProfile(user.user, {
          displayName: userName,
          photoURL: "",
        })
          .then(async () => {
            // window.location.protocol
            // "https://" + urlDetails.domain + "/signup-validation"
            // //console.log("continueUrl=", "https://" + domainDetails.domain + "/signup-validation");
            const actionCodeSettings = {
              continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
              url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
              // url: "http://localhost:3000/signup-validation?email=user@example.com",
              // iOS: {
              //   bundleId: "com.example.ios",
              // },
              // android: {
              //   packageName: "com.example.android",
              //   installApp: true,
              //   minimumVersion: "12",
              // },
              handleCodeInApp: false,
            };
            if (false) {
              //Desabilitado em 10/10/2023 por Fernando Villela para impedir envio de email de verificação, por enquanto.
              await sendEmailVerification(user.user, actionCodeSettings)
                .then(() => { })
                .catch((error) => {
                  //console.log(error);
                });
            }
          })
          .catch((error) => {
            //console.log(error);
          });
        return user;
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const signupWithoutLogoutCurrentUser = async (userName, email, password) => {
    let returnUser = undefined;
    return await createUserWithEmailAndPassword(secondaryAuth, email, password)
      .then(async (user) => {
        // //console.log("user=", user);
        returnUser = user;
        let newUser = {
          dadosPessoais: {
            name: userName,
          },
          dadosContato: {
            email: email,
            celular: "",
          },
          metadados: {
            ownerid: user.user.uid ? user.user.uid : "",
            owner: userName ? userName : "",
            creation: Timestamp.fromDate(new Date()),
            date: Timestamp.fromDate(new Date()),
            version: 1,
            active: true,
          },
          usersgroupsowned: [],
        };
        await UsersService.createWithID(user.user.uid, newUser);
        return await updateProfile(user.user, {
          displayName: userName,
          photoURL: "",
        })
          .then(async () => {
            const actionCodeSettings = {
              continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
              url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
              handleCodeInApp: false,
            };
            if (false) {
              //Desabilitado em 10/10/2023 por Fernando Villela para impedir envio de email de verificação, por enquanto.
              await sendEmailVerification(user.user, actionCodeSettings)
                .then(() => { })
                .catch((error) => {
                  //console.log(error);
                });
            }
            return returnUser;
          })
          .catch((error) => {
            //console.log(error);
          });
        // .finally(() => {
        //   //console.log("finally -> user=", user);
        //   return user;
        // });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          //console.log(error);
          throw new Error("Este e-mail já está em uso!");
        } else {
          //console.log(error);
          throw new Error("Erro ao criar usuário!");
        }
        //console.log(error);
        //console.log(error.FirebaseError);
        //console.log(error.FirebaseError?.Firebase);
        // //console.log("error.errorInfo:", error.errorInfo);
        // //console.log("error.errorInfo.code:", error.errorInfo.code);
        //console.log(error.response?.data?.message); // now works
        //console.log(error.response?.data?.statusCode);
        //console.log(error.message);
        //console.log(error.code);
        // if (error.response?.data?.statusCode === 401) {
        //   //console.log(error.response?.data?.statusCode);
        // }
      });
  };

  const login = async (email, password) => {
    let cadastroUsuarioCompleto = false;
    let cadastroPraticanteCompletoOK = false;
    return await signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      if (!userCredential.user.emailVerified) {
        reSendEmailVerification(userCredential);
      }
      await UsersService.getOne(userCredential.user.uid)
        .then(async (userRetrieved) => {
          cadastroUsuarioCompleto = userRetrieved !== undefined ? true : false;
          return true;
        })
        .catch((error) => {
          //console.log(error);
        });
      await GenericService.getOne("praticantes", userCredential.user.uid)
        .then(async (praticanteRetrieved) => {
          cadastroPraticanteCompletoOK = praticanteRetrieved !== undefined ? true : false;
          return true;
        })
        .catch(async (error) => {
          //console.log(error);
        });
      return cadastroUsuarioCompleto && cadastroPraticanteCompletoOK;
    });
  };

  const logout = () => {
    return signOut(auth).then(() => {
      return true;
    });
  };

  const forgetPasswordSendEmail = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const updateUserProfile = (auth, userDisplayName, userPhotoURL) => {
    return updateProfile(auth, {
      displayName: userDisplayName,
      photoURL: userPhotoURL,
    }).catch((error) => {
      //console.log(error);
    });
  };

  const reSendEmailVerification = async (auth) => {
    // const actionCodeSettings = {
    //   // continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
    //   url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
    //   handleCodeInApp: false,
    // };

    // const actionCodeSettings2 = {
    //   continueUrl: "https://sg-cbtkd.firebaseapp.com/__/auth/action?mode=action&oobCode=code",
    //   url: "https://sg-cbtkd.firebaseapp.com/__/auth/action?mode=action&oobCode=code",
    //   handleCodeInApp: false,
    // };

    // const actionCodeSettings3 = {
    //   continueUrl: "https://sg-cbtkd.firebaseapp.com/signup-validation",
    //   url: "https://sg-cbtkd.firebaseapp.com/signup-validation",
    //   handleCodeInApp: false,
    // };

    // //console.log("auth=", auth);
    // //console.log("auth.user=", auth.user);
    // //console.log("auth.currentUser=", auth.currentUser);
    // //console.log("Email será enviado!!!, com actionCodeSettings=", actionCodeSettings);
    //console.log("Email será enviado!!!");
    // return sendEmailVerification(auth.user, actionCodeSettings)
    //   .then(() => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    // return await sendEmailVerification(auth.user, actionCodeSettings)
    //   .then(async (code) => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    // return await sendEmailVerification(auth.user)
    //   .then(async (code) => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    // return await auth.user
    //   .sendEmailVerification()
    //   .then(async () => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });
    // const mauth = getAuth();
    // //console.log("mauth=", mauth);
    // return await sendEmailVerification(mauth.currentUser)
    //   .then(() => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    const mauth = getAuth();
    // mauth.useDeviceLanguage();
    // //console.log("mauth=", mauth);
    // return await sendEmailVerification(getAuth().currentUser)
    // return await sendEmailVerification(getAuth().currentUser, null)
    // return await sendEmailVerification(getAuth().currentUser, undefined)
    // //console.log("actionCodeSettings=", actionCodeSettings); //9:35
    // return await sendEmailVerification(mauth.currentUser, actionCodeSettings)
    // //console.log("actionCodeSettings=", actionCodeSettings2); //9:42
    // return await sendEmailVerification(getAuth().currentUser, actionCodeSettings2)
    // //console.log("actionCodeSettings=", actionCodeSettings3);
    // return await sendEmailVerification(getAuth().currentUser, actionCodeSettings3)
    // .then(() => {
    //   //console.log("Email enviado com sucesso!!!");
    // })
    // .catch((error) => {
    //   //console.log(error);
    // });

    // getAuth().onAuthStateChanged(function (user) {
    //   user.sendEmailVerification();
    //   //console.log("Email enviado com sucesso!!!");
    // });

    // getAuth().onAuthStateChanged((user) => {
    //   sendEmailVerification(user)
    //     .then(() => {
    //       //console.log("Email enviado com sucesso!!!");
    //     })
    //     .catch((error) => {
    //       //console.log(error);
    //     });
    // });

    // getAuth().userCredential.user.sendEmailVerification();

    const actionCodeSettings = {
      // continueUrl: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
      // url: window.location.protocol + "//" + domainDetails.domain + "/signup-validation",
      url: "https://sg-cbtkd.web.app/signup-validation",
      handleCodeInApp: false,
    };
    if (false) {
      //Desabilitado em 10/10/2023 por Fernando Villela para impedir envio de email de verificação, por enquanto.
      await sendEmailVerification(mauth.currentUser, actionCodeSettings)
        .then(() => {
          //console.log("Email enviado com sucesso!!! Com actionCodeSettings=", actionCodeSettings);
        })
        .catch((error) => {
          //console.log(error);
        });
    }
    return true;

    // return await sendEmailVerification(auth.user, actionCodeSettings)
    //   .then(() => {
    //     //console.log("Email enviado com sucesso!!!");
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });

    // Sends a verification email to a user.

    // @remarks — The verification process is completed by calling applyActionCode.

    // @example

    // const actionCodeSettings = {
    //   url: 'https://www.example.com/?email=user@example.com',
    //   iOS: {
    //      bundleId: 'com.example.ios'
    //   },
    //   android: {
    //     packageName: 'com.example.android',
    //     installApp: true,
    //     minimumVersion: '12'
    //   },
    //   handleCodeInApp: true
    // };
    // await sendEmailVerification(user, actionCodeSettings);
    // // Obtain code from the user.
    // await applyActionCode(auth, code);
    // @param user — The user.

    // @param actionCodeSettings — The ActionCodeSettings.

    // @public

    // auth
  };

  // const updateCelular = async (auth, userPhoneNumber) => {
  //   // 'recaptcha-container' is the ID of an element in the DOM.
  //   const applicationVerifier = new RecaptchaVerifier("recaptcha-container");
  //   const provider = new PhoneAuthProvider(auth);
  //   const verificationId = await provider.verifyPhoneNumber(
  //     userPhoneNumber,
  //     applicationVerifier
  //   );
  //   // Obtain the verificationCode from the user.
  //   const phoneCredential = PhoneAuthProvider.credential(
  //     verificationId,
  //     verificationCode
  //   );
  //   return await updatePhoneNumber(auth, phoneCredential).catch((error) => {
  //     //console.log(error);
  //   });

  //   // return updatePhoneNumber(auth, {
  //   //   displayName: userDisplayName,
  //   //   photoURL: userPhotoURL,
  //   // }).catch((error) => {
  //   //   //console.log(error);
  //   // });
  // };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        domainDetails,
        changePortal,
        signup,
        signupWithoutLogoutCurrentUser,
        login,
        logout,
        existEmail,
        forgetPasswordSendEmail,
        updateUserProfile,
        reSendEmailVerification,
        changePassword,
        changeEmail,
        changeName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const AuthContextProvider = memo(AuthContextProviderComponent);
