export const getModalidadeByCodename = (codename) => {
  let modalidade = "";
  switch (codename) {
    case "kyoruguiPretas":
      modalidade = "Kyorugui Pretas";
      break;
    case "kyoruguiColoridas":
      modalidade = "Kyorugui Coloridas";
      break;
    case "paraKyoruguiPretas_K40":
      modalidade = "Para-Kyorugui Pretas K40";
      break;
    case "paraKyoruguiColoridas_K40":
      modalidade = "Para-Kyorugui Coloridas K40";
      break;
    case "paraKyoruguiPretas_K60":
      modalidade = "Para-Kyorugui Pretas K60";
      break;
    case "paraKyoruguiColoridas_K60":
      modalidade = "Para-Kyorugui Coloridas K60";
      break;

    case "paraPoomsaeReconhecidoIndividualPretas":
      modalidade = "Para-Poomsae Reconhecido Individual Pretas";
      break;
    case "paraPoomsaeReconhecidoIndividualColoridas":
      modalidade = "Para-Poomsae Reconhecido Individual Coloridas";
      break;
    case "paraPoomsaeReconhecidoDuplaPretas":
      modalidade = "Para-Poomsae Reconhecido Dupla Mista Pretas";
      break;
    case "paraPoomsaeReconhecidoDuplaColoridas":
      modalidade = "Para-Poomsae Reconhecido Dupla Mista Coloridas";
      break;
    case "paraPoomsaeReconhecidoEquipePretas":
      modalidade = "Para-Poomsae Reconhecido Equipe Pretas";
      break;
    case "paraPoomsaeReconhecidoEquipeColoridas":
      modalidade = "Para-Poomsae Reconhecido Equipe Coloridas";
      break;
  

    case "poomsaeReconhecidoIndividualPretas":
      modalidade = "Poomsae Reconhecido Individual Pretas";
      break;
    case "poomsaeReconhecidoIndividualColoridas":
      modalidade = "Poomsae Reconhecido Individual Coloridas";
      break;
    case "poomsaeReconhecidoDuplaPretas":
      modalidade = "Poomsae Reconhecido Dupla Mista Pretas";
      break;
    case "poomsaeReconhecidoDuplaColoridas":
      modalidade = "Poomsae Reconhecido Dupla Mista Coloridas";
      break;
    case "poomsaeReconhecidoEquipePretas":
      modalidade = "Poomsae Reconhecido Equipe Pretas";
      break;
    case "poomsaeReconhecidoEquipeColoridas":
      modalidade = "Poomsae Reconhecido Equipe Coloridas";
      break;
    case "poomsaeFreeStyleIndividualPretas":
      modalidade = "Poomsae FreeStyle Individual Pretas";
      break;
    case "poomsaeFreeStyleIndividualColoridas":
      modalidade = "Poomsae FreeStyle Individual Coloridas";
      break;
    case "poomsaeFreeStyleDuplaPretas":
      modalidade = "Poomsae FreeStyle Dupla Mista Pretas";
      break;
    case "poomsaeFreeStyleDuplaColoridas":
      modalidade = "Poomsae FreeStyle Dupla Mista Coloridas";
      break;
    case "poomsaeFreeStyleEquipePretas":
      modalidade = "Poomsae FreeStyle Equipe Pretas";
      break;
    case "poomsaeFreeStyleEquipeColoridas":
      modalidade = "Poomsae FreeStyle Equipe Coloridas";
      break;

    case "tecnicos":
      modalidade = "Técnicos";
      break;
    case "outros":
      modalidade = "Outros";
      break;
    case "voluntarios":
      modalidade = "Voluntários";
      break;
    case "dirigentes":
      modalidade = "Dirigentes";
      break;
    case "comissaoTecnica":
      modalidade = "Comissão Técnica";
      break;
    case "arbitros":
      modalidade = "Árbitros";
      break;
    case "staffs":
      modalidade = "Staffs";
      break;

      case "passagemFaixaKukkiwon":
      modalidade = "Passagem de Faixa Kukkiwon";
      break;
    default:
      console.log("Modalidade não encontrada: " + codename);
      modalidade = codename;
  }
  return modalidade;
};