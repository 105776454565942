import { db } from "../utils/firestore";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  // deleteDoc,
  query,
  orderBy,
  where,
  // whereArrayContains,
  // startAfter,
  // endBefore,
  limit,
  setDoc,
  Timestamp,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
// import { uniq } from "../utils/arrayUtils";
// import { TimerOutlined } from "@material-ui/icons";
import { generateTrigam } from "../utils/trigamUtils";
import GenericService from "./Generic.service";
import GenericVinculadoService from "./GenericVinculado.service";
//const dbCollection = db.collection("/praticantesgroups");
import axios from "axios";
import { UserModelo } from "../pages/security/users/models/user.modelo";
import UsersService from "./Users.Service";

class praticantesService {
  //import { db } from '../utils/firestore'; // update with your path to firestore config
  //import { doc, getDoc } from "firebase/firestore";

  // getRulesOwned = async (id) => {
  //   var allRulesOwned = [];
  //   try {
  //     if (id) {
  //       await getDoc(doc(db, "praticantes", id)).then(async (userSnapshot) => {
  //         if (userSnapshot.exists()) {
  //           let praticantesgroupsowned = userSnapshot.data().praticantesgroupsowned;
  //           await getDocs(query(collection(db, "praticantesgroups"), where("metadataactive", "==", true))).then((praticantesGroups) => {
  //             if (praticantesGroups) {
  //               praticantesGroups.docs.map((doc) => {
  //                 if (praticantesgroupsowned.includes(doc.id)) {
  //                   allRulesOwned = [...allRulesOwned, ...doc.data().rulesowned];
  //                 }
  //               });
  //             }
  //           });
  //         }
  //       });
  //       return uniq(allRulesOwned);
  //     } else {
  //       throw new Error("Registro não encontrado!");
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  //usage: getUsersGroupsOwned(id);

  // getOne = async (id) => {
  //   try {
  //     if (id) {
  //       const noteSnapshot = await getDoc(doc(db, "praticantes", id));
  //       if (noteSnapshot.exists()) {
  //         return noteSnapshot.data();
  //       } else {
  //         throw new Error("Registro não encontrado!");
  //       }
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  //usage: getNote(id);

  // getAll = async () => {
  //   const querySnapshot = await getDocs(
  //     query(
  //       collection(db, "praticantes"),
  //       // where("metadataactive", "==", true),
  //       orderBy("dadosPessoais.name", "asc")
  //     )
  //   );
  //   return querySnapshot.docs;
  // };

  // getCollectionSize = async (queryTarget, queryText, onlyActive, showAllVersions) => {
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   const querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc")));
  //   return querySnapshot.docs.length;
  // };

  // getFilteredCollection = async (filterTarget, operation, filterValue) => {
  //   const querySnapshot = await getDocs(
  //     query(collection(db, "praticantes"), where(filterTarget, operation, filterValue), orderBy("name", "asc"))
  //   );
  //   return querySnapshot.docs;
  // };

  // getConditionsFromQueryFilter = async (queryTarget, queryText, onlyActive, showAllVersions) => {
  //   const conditions = [];
  //   if (onlyActive) conditions.push(where("metadados.active", "==", true));
  //   if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
  //   // if (queryText.length > 0) {
  //   //   conditions.push(where(queryTarget, ">=", queryText));
  //   //   conditions.push(where(queryTarget, "<=", queryText + "\uf8ff"));
  //   // }
  //   if (queryText.length > 0) conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
  //   // //console.log("conditions=", conditions);
  //   return conditions;
  // };

  // getCollectionSearchByPrefix = async (queryTarget, queryText, onlyActive, showAllVersions) => {
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc")));
  //   return querySnapshot.docs;
  // };

  // getCollectionSearchActiveByPrefixLimited = async (queryTarget, queryText, queryLimit, onlyActive, showAllVersions) => {
  //   // //console.log("queryTarget=", queryTarget, " queryText=", queryText, " queryLimit=", queryLimit, " showAllVersions=", showAllVersions);
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   //console.log("conditions=", conditions);
  //   let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(queryLimit)));
  //   return querySnapshot.docs;
  // };

  // getCollectionSearchActiveByPrefixLimitedFiliadoAEntidade = async (
  //   queryTarget,
  //   queryText,
  //   queryLimit,
  //   onlyActive,
  //   showAllVersions,
  //   dadosFiliacao_federacaoNome
  // ) => {
  //   // //console.log("queryTarget=", queryTarget, " queryText=", queryText, " queryLimit=", queryLimit, " showAllVersions=", showAllVersions);
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   conditions.push(where("dadosFiliacao.federacao", "==", dadosFiliacao_federacaoNome));
  //   // //console.log("conditions=", conditions);
  //   let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(queryLimit)));
  //   return querySnapshot.docs;
  // };

  isNameRegistered = async (nameValue, mothersName) => {
    if (nameValue === undefined || nameValue === null || nameValue === "") return false;
    nameValue = nameValue.toLowerCase();
    // //console.log("isNameRegistered => nameValue=", nameValue);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), where("dadosPessoais.searchableName", "==", nameValue)));
    // //console.log("isNameRegistered => querySnapshot.docs.length=", querySnapshot.docs.length);
    let retValue = querySnapshot.docs.filter((doc) => doc.data().metadados.successorId === "");
    if (!retValue && mothersName !== undefined && mothersName !== null && mothersName !== "") {
      mothersName = mothersName.toLowerCase();
      retValue = querySnapshot.docs.filter((doc) => doc.data().dadosPessoais.mothersName.toLowerCase() === mothersName);
    }
    return retValue.length > 0
  };

  isCPFRegistered = async (cpfValue) => {
    // //console.log("isCPFRegistered => cpfValue=", cpfValue);
    if (cpfValue === undefined || cpfValue === null || cpfValue === "") return false;
    cpfValue = cpfValue.replace(/[^\d]+/g, "");
    // //console.log("isCPFRegistered => cpfValue=", cpfValue);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), where("dadosPessoais.cpf", "==", cpfValue)));
    // //console.log("isCPFRegistered => querySnapshot.docs.length=", querySnapshot.docs.length);
    return querySnapshot.docs.filter((doc) => doc.data().metadados.successorId === "").length > 0;
  };

  isEmailRegistered = async (emailValue) => {
    // //console.log("isEmailRegistered => emailValue=", emailValue);
    if (emailValue === undefined || emailValue === null || emailValue === "") return false;
    emailValue = emailValue.toLowerCase();
    // //console.log("isEmailRegistered => emailValue=", emailValue);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), where("dadosContato.email", "==", emailValue)));
    // //console.log("isEmailRegistered => querySnapshot.docs.length=", querySnapshot.docs.length);
    return querySnapshot.docs.filter((doc) => doc.data().metadados.successorId === "").length > 0;
  };

  getCPFFromPraticanteId = async (id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, "praticantes", id.toString()));
        if (noteSnapshot.exists()) {
          // //console.log("noteSnapshot.data()=", noteSnapshot.data());
          let praticante = noteSnapshot.data();
          // let praticante = await GenericService.getOne("praticantes", id);
          // if (praticante === null) return "Não informado";
          // //console.log("getCPFFromPraticanteId => praticante.dadosPessoais.cpf=", praticante.dadosPessoais.cpf ? praticante.dadosPessoais.cpf : "Não informado");
          return praticante.dadosPessoais.cpf ? praticante.dadosPessoais.cpf : "Não informado";
        } else {
          return "Não informado"
        }
      } else {
        return "Não informado";
      }
    } catch (error) {
      // throw error;
      //console.log(error);
      return "Não informado"
    }
  }

  getHistoricoGraduacoes = async (id) => {
    try {
      if (id) {
        let historicoGraduacoesVazio = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
        const noteSnapshot = await getDoc(doc(db, "praticantes", id.toString()));
        if (noteSnapshot.exists()) {
          // //console.log("noteSnapshot.data()=", noteSnapshot.data());
          let praticante = noteSnapshot.data();
          // let praticante = await GenericService.getOne("praticantes", id);
          // if (praticante === null) return "Não informado";
          // //console.log("getCPFFromPraticanteId => praticante.dadosPessoais.cpf=", praticante.dadosPessoais.cpf ? praticante.dadosPessoais.cpf : "Não informado");
          return praticante.dadosTaekwondo !== undefined ? praticante.dadosTaekwondo.HistoricoGraduacoes !== undefined ? praticante.dadosTaekwondo.HistoricoGraduacoes : historicoGraduacoesVazio : historicoGraduacoesVazio;
        } else {
          return historicoGraduacoesVazio;
        }
      } else {
        return "Não informado";
      }
    } catch (error) {
      // throw error;
      //console.log(error);
      return "Não informado"
    }
  }

  getNacionalidadeComPraticanteId = async (id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, "praticantes", id.toString()));
        if (noteSnapshot.exists()) {
          // //console.log("noteSnapshot.data()=", noteSnapshot.data());
          let praticante = noteSnapshot.data();
          // let praticante = await GenericService.getOne("praticantes", id);
          // if (praticante === null) return "Não informado";
          // //console.log("getCPFFromPraticanteId => praticante.dadosPessoais.cpf=", praticante.dadosPessoais.cpf ? praticante.dadosPessoais.cpf : "Não informado");
          return praticante.dadosPessoais.nacionalidade ? praticante.dadosPessoais.nacionalidade : "Brasil";
        } else {
          return "Brasil"
        }
      } else {
        return "Brasil";
      }
    } catch (error) {
      // throw error;
      //console.log(error);
      return "Brasil"
    }
  }

  getNacionalidadeEHisoricoGraduacoesComPraticanteId = async (id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, "praticantes", id.toString()));
        if (noteSnapshot.exists()) {
          // //console.log("noteSnapshot.data()=", noteSnapshot.data());
          let praticante = noteSnapshot.data();
          // let praticante = await GenericService.getOne("praticantes", id);
          // if (praticante === null) return "Não informado";
          // //console.log("getCPFFromPraticanteId => praticante.dadosPessoais.cpf=", praticante.dadosPessoais.cpf ? praticante.dadosPessoais.cpf : "Não informado");
          return {
            nacionalidade: praticante.dadosPessoais.nacionalidade ? praticante.dadosPessoais.nacionalidade : "Brasil",
            historicoGraduacoes: praticante.dadosTaekwondo !== undefined ? praticante.dadosTaekwondo.HistoricoGraduacoes !== undefined ? praticante.dadosTaekwondo.HistoricoGraduacoes : ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""] : ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
          };
        } else {
          return {
            nacionalidade: "Brasil",
            historicoGraduacoes: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
          }
        }
      } else {
        return {
          nacionalidade: "Brasil",
          historicoGraduacoes: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
        };
      }
    } catch (error) {
      // throw error;
      //console.log(error);
      return {
        nacionalidade: "Brasil",
        historicoGraduacoes: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
      }
    }
  }


  getCollectionSearchActiveByPrefixLimitedFiliadoAEntidadeMinimalGraduacao = async (
    queryTarget,
    queryText,
    queryLimit,
    onlyActive,
    showAllVersions,
    dadosFiliacao_federacaoNome,
    dadosTaekwondo_graduacao
  ) => {
    //console.log(queryTarget, queryText, queryLimit, onlyActive, showAllVersions, dadosFiliacao_federacaoNome, dadosTaekwondo_graduacao);
    let conditions = await GenericVinculadoService.getConditionsFromQueryFilter(queryTarget, queryText);
    conditions.push(where("dadosFiliacao.federacao", "==", dadosFiliacao_federacaoNome));
    if (onlyActive) conditions.push(where("metadados.active", "==", true));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    //console.log("conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(queryLimit)));

    let filteredDocs = [];
    if (dadosTaekwondo_graduacao) {
      querySnapshot.docs.forEach((doc) => {
        if (doc.data().dadosTaekwondo.maxGraduacao >= dadosTaekwondo_graduacao) {
          filteredDocs.push(doc);
        }
      });
    } else {
      filteredDocs = querySnapshot.docs;
    }
    return filteredDocs;
  };

  getCollectionSearchActiveByPrefixLimitedFiliadoAEntidadeComGraduacao = async (
    queryTarget,
    queryText,
    queryLimit,
    onlyActive,
    showAllVersions,
    dadosFiliacao_federacaoNome,
    dadosTaekwondo_graduacaoLista,
    dadosFiliacao_vinculadoAEntidadeNome,
    lastVisible,
    operation
  ) => {
    // console.log(
    //   "queryTarget=",
    //   queryTarget,
    //   " queryText=",
    //   queryText,
    //   " queryLimit=",
    //   queryLimit,
    //   " showAllVersions=",
    //   showAllVersions,
    //   "dadosFiliacao_federacaoNome=",
    //   dadosFiliacao_federacaoNome,
    //   "dadosTaekwondo_graduacaoLista=",
    //   dadosTaekwondo_graduacaoLista,
    //   "dadosFiliacao_vinculadoAEntidadeNome=",
    //   dadosFiliacao_vinculadoAEntidadeNome,
    //   " lastVisible=",
    //   lastVisible,
    //   "operation=",
    //   operation
    // );

    let conditions = [];
    if (queryText.length > 0) conditions = await GenericService.getConditionsFromQueryFilter(queryTarget, queryText);
    conditions.push(where("dadosFiliacao.federacao", "==", dadosFiliacao_federacaoNome));
    if (onlyActive) conditions.push(where("metadados.active", "==", true));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    if (
      (operation === "not-in" && dadosTaekwondo_graduacaoLista.length > 0) ||
      (operation === "in" && dadosTaekwondo_graduacaoLista.length <= 10 && dadosTaekwondo_graduacaoLista.length > 0)
    ) {
      // //console.log("where(dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista, ");");
      conditions.push(where("dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista));
    }
    if (operation === "not-in") {
      conditions.push(orderBy("dadosTaekwondo.maxGraduacao", "asc"));
    }

    if (dadosFiliacao_vinculadoAEntidadeNome)
      conditions.push(where("dadosFiliacao.vinculadoAEntidade", "==", dadosFiliacao_vinculadoAEntidadeNome));
    conditions.push(orderBy(queryTarget, "asc"));
    if (queryLimit !== undefined && queryLimit !== null && queryLimit !== "") conditions.push(limit(queryLimit));
    if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    // console.log("conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions));
    return querySnapshot;
    // let filteredDocs = [];
    // if (dadosTaekwondo_graduacaoLista) {
    //   querySnapshot.docs.forEach((doc) => {
    //     if (doc.data().dadosTaekwondo.maxGraduacao in dadosTaekwondo_graduacaoLista) {
    //       filteredDocs.push(doc);
    //     }
    //   });
    // } else {
    //   filteredDocs = querySnapshot.docs;
    // }
    // return filteredDocs;
  };


  getCollectionSearchActiveByPrefixLimitedFiliadoAEntidadeComGraduacaoVersion2 = async (
    queryTarget,
    queryText,
    queryLimit,
    onlyActive,
    showAllVersions,
    dadosFiliacao_federacaoNome,
    // dadosTaekwondo_graduacaoLista,
    dadosFiliacao_vinculadoAEntidadeNome,
    lastVisible,
    operation
  ) => {
    console.log("getCollectionSearchActiveByPrefixLimitedFiliadoAEntidadeComGraduacaoVersion2(",
      "queryTarget=", queryTarget,
      "queryText=", queryText,
      "queryLimit=", queryLimit,
      "onlyActive=", onlyActive,
      "showAllVersions=", showAllVersions,
      "dadosFiliacao_federacaoNome=", dadosFiliacao_federacaoNome,
      "dadosFiliacao_vinculadoAEntidadeNome=", dadosFiliacao_vinculadoAEntidadeNome,
      "lastVisible=", lastVisible,
      "operation=", operation,
      ");"
    )
    let conditions = [];
    if (queryText.length > 0) conditions = await GenericService.getConditionsFromQueryFilter(queryTarget, queryText);
    conditions.push(where("dadosFiliacao.federacao", "==", dadosFiliacao_federacaoNome));
    if (onlyActive) conditions.push(where("metadados.active", "==", true));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    // if (
    //   (operation === "not-in" && dadosTaekwondo_graduacaoLista.length > 0) ||
    //   (operation === "in" && dadosTaekwondo_graduacaoLista.length <= 10 && dadosTaekwondo_graduacaoLista.length > 0)
    // ) {
    //   // //console.log("where(dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista, ");");
    //   conditions.push(where("dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista));
    // }
    // if (operation === "not-in") {
    //   conditions.push(orderBy("dadosTaekwondo.maxGraduacao", "asc"));
    // }

    if (dadosFiliacao_vinculadoAEntidadeNome)
      conditions.push(where("dadosFiliacao.vinculadoAEntidade", "==", dadosFiliacao_vinculadoAEntidadeNome));
    conditions.push(orderBy(queryTarget, "asc"));
    if (queryLimit !== undefined && queryLimit !== null && queryLimit !== "") conditions.push(limit(queryLimit));
    if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    console.log("conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions));
    return querySnapshot;
    // let filteredDocs = [];
    // if (dadosTaekwondo_graduacaoLista) {
    //   querySnapshot.docs.forEach((doc) => {
    //     if (doc.data().dadosTaekwondo.maxGraduacao in dadosTaekwondo_graduacaoLista) {
    //       filteredDocs.push(doc);
    //     }
    //   });
    // } else {
    //   filteredDocs = querySnapshot.docs;
    // }
    // return filteredDocs;
  };

  getCollectionSearchActiveByPrefixLimitedFiliadoAEntidadeComGraduacaoVersion2 = async (
    queryTarget,
    queryText,
    queryLimit,
    onlyActive,
    showAllVersions,
    dadosFiliacao_federacaoNome,
    // dadosTaekwondo_graduacaoLista,
    dadosFiliacao_vinculadoAEntidadeNome,
    lastVisible,
    operation
  ) => {
    // console.log(
    //   "queryTarget=",
    //   queryTarget,
    //   " queryText=",
    //   queryText,
    //   " queryLimit=",
    //   queryLimit,
    //   " showAllVersions=",
    //   showAllVersions,
    //   "dadosFiliacao_federacaoNome=",
    //   dadosFiliacao_federacaoNome,
    //   "dadosTaekwondo_graduacaoLista=",
    //   dadosTaekwondo_graduacaoLista,
    //   "dadosFiliacao_vinculadoAEntidadeNome=",
    //   dadosFiliacao_vinculadoAEntidadeNome,
    //   " lastVisible=",
    //   lastVisible,
    //   "operation=",
    //   operation
    // );

    let conditions = [];
    if (queryText.length > 0) conditions = await GenericService.getConditionsFromQueryFilter(queryTarget, queryText);
    conditions.push(where("dadosFiliacao.federacao", "==", dadosFiliacao_federacaoNome));
    if (onlyActive) conditions.push(where("metadados.active", "==", true));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    // if (
    //   (operation === "not-in" && dadosTaekwondo_graduacaoLista.length > 0) ||
    //   (operation === "in" && dadosTaekwondo_graduacaoLista.length <= 10 && dadosTaekwondo_graduacaoLista.length > 0)
    // ) {
    //   // //console.log("where(dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista, ");");
    //   conditions.push(where("dadosTaekwondo.maxGraduacao", operation, dadosTaekwondo_graduacaoLista));
    // }
    // if (operation === "not-in") {
    //   conditions.push(orderBy("dadosTaekwondo.maxGraduacao", "asc"));
    // }

    if (dadosFiliacao_vinculadoAEntidadeNome)
      conditions.push(where("dadosFiliacao.vinculadoAEntidade", "==", dadosFiliacao_vinculadoAEntidadeNome));
    conditions.push(orderBy(queryTarget, "asc"));
    if (queryLimit !== undefined && queryLimit !== null && queryLimit !== "") conditions.push(limit(queryLimit));
    if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    console.log("conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions));
    return querySnapshot;
    // let filteredDocs = [];
    // if (dadosTaekwondo_graduacaoLista) {
    //   querySnapshot.docs.forEach((doc) => {
    //     if (doc.data().dadosTaekwondo.maxGraduacao in dadosTaekwondo_graduacaoLista) {
    //       filteredDocs.push(doc);
    //     }
    //   });
    // } else {
    //   filteredDocs = querySnapshot.docs;
    // }
    // return filteredDocs;
  };

  // getPrevPage = async (firstVisible, pageSize, queryTarget, queryText, onlyActive, showAllVersions) => {
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   let querySnapshot = await getDocs(
  //     query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(pageSize), endBefore(firstVisible))
  //   );
  //   return querySnapshot.docs;
  // };

  // getNextPage = async (lastVisible, pageSize, queryTarget, queryText, onlyActive, showAllVersions) => {
  //   let conditions = await this.getConditionsFromQueryFilter(queryTarget, queryText, onlyActive, showAllVersions);
  //   let querySnapshot = null;
  //   if (lastVisible !== null) {
  //     querySnapshot = await getDocs(
  //       query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(pageSize), startAfter(lastVisible))
  //     );
  //   } else {
  //     querySnapshot = await getDocs(query(collection(db, "praticantes"), ...conditions, orderBy(queryTarget, "asc"), limit(pageSize)));
  //   }
  //   return querySnapshot.docs;
  // };

  newID = async () => {
    const querySnapshot = await getCountFromServer(query(collection(db, "praticantes"), where("metadados.successorId", "==", "")));
    //console.log("newID => querySnapshot.data().count=", querySnapshot.data().count);
    if (querySnapshot) {
      return Number((querySnapshot.data().count) + 5000);
    } else {
      return 50000;
    }
  };

  create = async (value) => {
    try {
      // value = { ...value, matriculaCBTKD: await this.newID(), dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
      // return await addDoc(collection(db, "praticantes"), value);
      return await this.createWithID(null, value);
    } catch (error) {
      throw error;
    }
  };

  createWithID = async (UID, value, doNotCreateAuthenticationUser) => {
    try {
      // console.log("createWithID => UID=", UID, "value=", value, "doNotCreateAuthenticationUser=", doNotCreateAuthenticationUser);
      if (doNotCreateAuthenticationUser === undefined || doNotCreateAuthenticationUser === false) {
        let userAuth = await this.createAuthenticationUser(UID, value.dadosContato.email, value.dadosPessoais.name);
        if (userAuth === null) throw new Error("Erro ao criar usuário no Firebase Authentication!");
        if (UID === null || UID === "") UID = userAuth.data.uid;
        //create users
        let newUser = UserModelo({ uid: value.metadados.ownerid, displayName: value.metadados.owner });
        newUser = {
          ...newUser,
          dadosPessoais: {
            ...newUser.dadosPessoais,
            name: value.dadosPessoais.name,
            searchableName: value.dadosPessoais.name.toLowerCase(),
            trigam: generateTrigam(value.dadosPessoais.name),
          }
        };
        // let returnValue = 
        await setDoc(doc(db, "users", UID.toString()), newUser);
      }

      value = { ...value, matriculaCBTKD: await this.newID(), dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
      if (isNaN(value.metadados.creation)) value.metadados.creation = Timestamp.fromDate(new Date());
      if (value.metadados.owner === null) value.metadados.owner = value.dadosPessoais.name;
      return setDoc(doc(db, "praticantes", UID.toString()), value).then(() => {
        this.updateView(UID);
      });
    } catch (error) {
      throw error;
    }
  };

  verifyEmail = async (email) => {
    try {
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/chkemail";
      await axios.post(urlService, { email: email }).then((response) => {
        //console.log(response);
        return response.data.value;
      }, (error) => {
        //console.log(error);
        return false;
      });
    } catch (error) {
      throw error;
    }
  };

  // createWithIDAndPortalID = async (UID, value, PortalID) => {
  //   try {
  //     if (isNaN(value.metadados.creation)) {
  //       value.metadados.creation = Timestamp.fromDate(new Date());
  //     }
  //     if (value.metadados.owner === null) value.metadados.owner = value.dadosPessoais.name;
  //     return await setDoc(doc(db, "praticantes", UID.toString()), value).then(async (doc) => {
  //       if (value.hasOwnProperty("administrativo")) {
  //         await getDoc(doc(db, "users", UID.toString())).then(async (userdoc) => {
  //           if (userdoc.exists()) {
  //             let user = userdoc.data();
  //             //console.log("createWithIDAndPortalID => Antes das alterações: user=", user);
  //             user.usersgroupowned.map((group) => {
  //               if (group.portalID === PortalID) {
  //                 group.groupOwned.push(doc.praticantes.rulesOwned);
  //                 user.usersgroupowned.push(group);
  //                 //console.log("createWithIDAndPortalID => Após alterações: user=", user);
  //               }
  //             });
  //             // await setDoc(doc(db, "users", UID.toString()), user);
  //           }
  //         });
  //       }
  //       return doc;
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  updateAndPortalID = async (id, value, PortalID) => {
    console.log("updateAndPortalID => id=", id, "value=", value, "PortalID=", PortalID);
    value = { ...value, dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
    try {
      if (value.matriculaCBTKD === "") {
        value = { ...value, matriculaCBTKD: await this.newID() };
      }
      return await GenericService.getOne("praticantes", id).then(async (retData) => {
        let oldVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: false,
            successorId: id,
          },
        };
        return await addDoc(collection(db, "praticantes"), oldVersion).then(async (lastVersion) => {
          let ActualVersion = {
            ...value,
            metadados: {
              ...value.metadados,
              version: value.metadados.version + 1,
              successorId: "",
            },
          };
          console.log("updateAndPortalID => id=", id, "oldVersion=", oldVersion, "ActualVersion=", ActualVersion);
          return await updateDoc(doc(db, "praticantes", id), ActualVersion).then(async () => {
            this.updateView(id);
            console.log("updateAndPortalID => Passo 1");
            if (ActualVersion.dadosColaborador !== oldVersion.dadosColaborador) {
              if (
                oldVersion.dadosColaborador.hasOwnProperty("administrativo") &&
                !value.dadosColaborador.hasOwnProperty("administrativo")
              ) {
                console.log("updateAndPortalID => Passo 11");
                return await this.updateUserSupressRulesByIdDepatamentAndIdCargo(
                  id,
                  PortalID,
                  oldVersion.dadosColaborador.administrativo.uidDepartamento,
                  oldVersion.dadosColaborador.administrativo.uidCargoColaborador
                ).then(async () => {
                  console.log("updateAndPortalID => Passo 2");
                  return await this.updateEntiteWithColaboradorDepartamentoCargo(
                    id,
                    value,
                    oldVersion.dadosColaborador.administrativo.idEmpregador
                  );
                });
              } else if (
                oldVersion.dadosColaborador.hasOwnProperty("administrativo") &&
                value.dadosColaborador.hasOwnProperty("administrativo")
              ) {
                console.log("updateAndPortalID => Passo 3");
                if (
                  oldVersion.dadosColaborador.administrativo.uidDepartamento !== "" &&
                  oldVersion.dadosColaborador.administrativo.uidCargoColaborador !== ""
                ) {
                  console.log("updateAndPortalID => Passo 4");
                  return await this.updateUserAdjustRulesByIdDepatamentAndIdCargo( //Este método foi executado na inclusão do colaborador
                    id,
                    PortalID,
                    oldVersion.dadosColaborador.administrativo.uidDepartamento,
                    oldVersion.dadosColaborador.administrativo.uidCargoColaborador,
                    value.dadosColaborador.administrativo.uidDepartamento,
                    value.dadosColaborador.administrativo.uidCargoColaborador
                  ).then(async () => {
                    console.log("updateAndPortalID => Passo 5");
                    return await this.updateEntiteWithColaboradorDepartamentoCargo( //Este método foi executado na inclusão do colaborador
                      id,
                      value,
                      value.dadosColaborador.administrativo.idEmpregador
                    );
                  });
                } else {
                  console.log("updateAndPortalID => Passo 6");
                  return await this.updateUserAddRulesByIdDepatamentAndIdCargo(
                    id,
                    PortalID,
                    value.dadosColaborador.administrativo.uidDepartamento,
                    value.dadosColaborador.administrativo.uidCargoColaborador
                  ).then(async () => {
                    console.log("updateAndPortalID => Passo 7");
                    return await this.updateEntiteWithColaboradorDepartamentoCargo(
                      id,
                      value,
                      value.dadosColaborador.administrativo.idEmpregador
                    );
                  });
                }
              } else if (value.dadosColaborador.hasOwnProperty("administrativo")) {
                console.log("updateAndPortalID => Passo 8");
                return await this.updateUserAddRulesByIdDepatamentAndIdCargo(
                  id,
                  PortalID,
                  value.dadosColaborador.administrativo.uidDepartamento,
                  value.dadosColaborador.administrativo.uidCargoColaborador
                ).then(async () => {
                  console.log("updateAndPortalID => Passo 9");
                  return await this.updateEntiteWithColaboradorDepartamentoCargo(
                    id,
                    value,
                    value.dadosColaborador.administrativo.idEmpregador
                  );
                });
              } else {
                console.log("Não vai rodar nenhuma das anteriores!");
                return doc;
              }
            }
          });
        });
      });
    } catch (error) {
      throw error;
    }
  };

  update = async (id, value) => {
    value = { ...value, dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
    //console.log("id=", id, "value=", value);
    try {
      if (value.matriculaCBTKD === "") {
        value = { ...value, matriculaCBTKD: await this.newID() };
      }
      GenericService.getOne("praticantes", id).then((retData) => {
        //console.log("retData=", retData);
        let oldVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: false,
            successorId: id,
          },
        };
        addDoc(collection(db, "praticantes"), oldVersion).then((lastVersion) => {
          let ActualVersion = {
            ...value,
            metadados: {
              ...value.metadados,
              version: value.metadados.version + 1,
              active: retData.metadados.active,
              successorId: "",
            },
          };
          //console.log("update => id=", id, "ActualVersion=", ActualVersion);
          updateDoc(doc(db, "praticantes", id), ActualVersion).then(async (retValue) => {
            if (oldVersion.dadosContato.email !== "" && oldVersion.dadosContato.email !== ActualVersion.dadosContato.email) {
              UsersService.updateEmail(id, ActualVersion.dadosContato.email).catch((error) => {
                console.log(error);
                throw error;
              });
              // let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/changeemail";
              // axios.post(urlService, {uid: id, email: ActualVersion.dadosContato.email})
              // .then((response) => {
              //   //console.log(response);
              //   if (response.data.value) {
              //     //console.log("Atualizou o e-mail no Firebase Authentication!");

              //   }
              //   // //console.log(response.data.data.pdf.charge);
              //   // if (callbackFunction !== undefined && response.data 
              //   //   && response.data.data && response.data.data.pdf) {
              //   //   callbackFunction(docRef.id, response.data.data.pdf.charge);
              //   // }
              // }, (error) => {
              //   //console.log(error);
              //   // ConsoleLog.log(error);
              // });
            }

            if (oldVersion.dadosPessoais.name !== "" && oldVersion.dadosPessoais.name !== ActualVersion.dadosPessoais.name) {
              UsersService.updateName(id, ActualVersion.dadosPessoais.name);
              // let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/changedisplayname";
              // axios.post(urlService, {uid: id, displayname: ActualVersion.dadosPessoais.name})
              // .then((response) => {
              //   //console.log(response);
              //   if (response.data.value) {
              //     //console.log("Atualizou o nome no Firebase Authentication!");
              //   }
              //   // //console.log(response.data.data.pdf.charge);
              //   // if (callbackFunction !== undefined && response.data 
              //   //   && response.data.data && response.data.data.pdf) {
              //   //   callbackFunction(docRef.id, response.data.data.pdf.charge);
              //   // }
              // }, (error) => {
              //   //console.log(error);
              //   // ConsoleLog.log(error);
              // });
            }
            this.updateView(id);
            return retValue;
          });
        });
      });
    } catch (error) {
      throw error;
    }
  };

  updateActiveState = async (id, newStateValue) => {
    // //console.log("updateActiveState => id = ", id, " newStateValue = ", newStateValue);
    try {
      GenericService.getOne("praticantes", id).then(async (retData) => {
        let ActualVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: newStateValue,
          },
        };
        if (ActualVersion.matriculaCBTKD === "") {
          ActualVersion = { ...ActualVersion, matriculaCBTKD: await this.newID() };
        }
        updateDoc(doc(db, "praticantes", id), ActualVersion).then((retValue) => {
          this.updateView(id);
          return retValue;
        });
      });
    } catch (error) {
      throw error;
    }
  };

  updatePropertyOnVinculo = async (id, propertyName, propertyValue) => {
    updateDoc(doc(db, "praticantes", id), { [propertyName]: propertyValue }, { merge: true })
      .then((retValue) => {
        // //console.log("retValue=", retValue);
        this.updateView(id);
        return retValue;
      }).catch((error) => {
        throw error;
      });
  };


  delete = async (id) => {
    try {
      GenericService.getOne("praticantes", id).then((retData) => {
        let ActualVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: false,
          },
        };
        updateDoc(doc(db, "praticantes", id), ActualVersion).then((retValue) => {
          this.updateView(id);
          return retValue;
        });
      });
    } catch (error) {
      throw error;
    }
  };

  async updateEntiteWithColaboradorDepartamentoCargo(id, value, idEmpregador) {
    // console.log("updateEntiteWithColaboradorDepartamentoCargo(id=", id, "value=", value, "idEmpregador=", idEmpregador, ")");
    // idEmpregador = getPortalId(idEmpregador);
    return await getDoc(doc(db, "entidades", idEmpregador))
      .then(async (entidadeSnapshot) => {
        if (entidadeSnapshot.exists()) {
          // console.log("entidadeSnapshot.data()=", entidadeSnapshot.data());
          let entidade = entidadeSnapshot.data();
          // console.log("entidade.colaboradores=", entidade.colaboradores);
          entidade.colaboradores.map(async (colaborador) => {
            if (colaborador.uid === id) {
              // console.log("colaborador=", colaborador);
              if (value.dadosColaborador.administrativo === undefined) return entidadeSnapshot;
              colaborador.DepartamentoCargo =
                value.dadosColaborador.administrativo.departamento + " / " + value.dadosColaborador.administrativo.cargoColaborador;
              // console.log("Vai rodar o updateDoc() com o valor de entidade=", entidade);
              return await updateDoc(doc(db, "entidades", idEmpregador), entidade)
                .then(async (retValue) => {
                  // console.log("retValue=", retValue);
                  this.updateView(id);
                  return retValue;
                })
                .catch((error) => {
                  console.log(error);
                  throw error;
                });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  async updateUserSupressRulesByIdDepatamentAndIdCargo(id, PortalID, uidDepartamento, uidCargoColaborador) {
    // //console.log(
    //   "updateUserSupressRulesByIdDepatamentAndIdCargo(id=",
    //   id,
    //   "PortalID=",
    //   PortalID,
    //   "uidDepartamento=",
    //   uidDepartamento,
    //   "uidCargoColaborador=",
    //   uidCargoColaborador,
    //   ")"
    // );
    console.log("updateUserSupressRulesByIdDepatamentAndIdCargo(id=", id, "PortalID=", PortalID, "uidDepartamento=", uidDepartamento, "uidCargoColaborador=", uidCargoColaborador, ")");
    if (uidDepartamento === undefined || uidCargoColaborador === undefined) return {};
    if (uidDepartamento === "" || uidCargoColaborador === "") return {};
    PortalID = await this.getPortalId(PortalID);
    return await getDoc(doc(db, "users", id)).then(async (userdoc) => {
      if (userdoc.exists()) {
        let user = userdoc.data();
        user.usersgroupsowned.map(async (group, indxGroup) => {
          if (group.portalID === PortalID) {
            return await getDoc(doc(db, "departamentos", uidDepartamento))
              .then(async (departamentodoc) => {
                if (departamentodoc.exists()) {
                  let departamento = departamentodoc.data();
                  departamento.cargos.map(async (cargo) => {
                    if (cargo.uid === uidCargoColaborador) {
                      let newRules = [];
                      cargo.rulesOwned.map(async (rule) => {
                        newRules = group.groupsOwned.filter(function (groupOwned) {
                          return groupOwned !== rule;
                        });
                      });
                      group.groupsOwned = [...newRules];
                      let updatedUser = {
                        ...user,
                        usersgroupsowned: [...user.usersgroupsowned],
                      };
                      // //console.log("updatedUser=", updatedUser);
                      user.usersgroupsowned[indxGroup] = group;
                      // return await setDoc(doc(db, "users", id), updatedUser);
                      Promise.all(user.usersgroupsowned).then(async (newUsersgroupsowned) => {
                        return await updateDoc(doc(db, "users", id), { usersgroupsowned: newUsersgroupsowned }, { merge: true })
                      });
                    }
                  });
                }
              })
              .catch((error) => {
                throw error;
              });
          }
        });
        return {};
      }
    });
  }

  async updateUserAddRulesByIdDepatamentAndIdCargo(id, PortalID, uidDepartamento, uidCargoColaborador) {
    console.log("updateUserAddRulesByIdDepatamentAndIdCargo(id=", id, "PortalID=", PortalID, "uidDepartamento=", uidDepartamento, ")");
    if (uidDepartamento === undefined || uidCargoColaborador === undefined) return {};
    if (uidDepartamento === "" || uidCargoColaborador === "") return {};
    PortalID = await this.getPortalId(PortalID);
    console.log("updateUserAddRulesByIdDepatamentAndIdCargo => PortalID=", PortalID);
    return await getDoc(doc(db, "users", id)).then(async (userdoc) => {
      if (userdoc.exists()) {
        let user = userdoc.data();
        let groupFounded = false;
        user.usersgroupsowned.map(async (group, indxGroup) => {
          console.log("group.portalID=", group.portalID, "PortalID=", PortalID);
          if (group.portalID === PortalID) {
            groupFounded = true;
            return await getDoc(doc(db, "departamentos", uidDepartamento))
              .then(async (departamentodoc) => {
                console.log("departamentodoc=", departamentodoc);
                if (departamentodoc.exists()) {
                  let departamento = departamentodoc.data();
                  departamento.cargos.map(async (cargo) => {
                    if (cargo.uid === uidCargoColaborador) {
                      cargo.rulesOwned.map(async (rule) => {
                        if (!group.groupsOwned.includes(rule)) group.groupsOwned.push(rule);
                        // group.groupsOwned = [...group.groupsOwned, rule];
                      });
                      console.log("group.groupsOwned=", group.groupsOwned);
                      console.log("cargo.rulesOwned=", cargo.rulesOwned);
                      // group.groupsOwned = [...group.groupsOwned, ...cargo.rulesOwned];
                      user.usersgroupsowned[indxGroup] = group;
                      // return await setDoc(doc(db, "users", id), user);
                      Promise.all(user.usersgroupsowned).then(async (newUsersgroupsowned) => {
                        return await updateDoc(doc(db, "users", id), { usersgroupsowned: newUsersgroupsowned }, { merge: true })
                      });
                    }
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                throw error;
              });
          }
        });
        if (!groupFounded) {
          console.log("groupFounded=", groupFounded);
          return await getDoc(doc(db, "departamentos", uidDepartamento))
            .then(async (departamentodoc) => {
              console.log("departamentodoc=", departamentodoc);
              if (departamentodoc.exists()) {
                let departamento = departamentodoc.data();
                departamento.cargos.map(async (cargo) => {
                  if (cargo.uid === uidCargoColaborador) {
                    let newGroup = {
                      portalID: PortalID,
                      groupsOwned: [...cargo.rulesOwned],
                    };
                    user.usersgroupsowned.push(newGroup);
                    Promise.all(user.usersgroupsowned).then(async (newUsersgroupsowned) => {
                      return await updateDoc(doc(db, "users", id), { usersgroupsowned: newUsersgroupsowned }, { merge: true })
                    });
                  }
                });
              }
            })
            .catch((error) => {
              console.log(error);
              throw error;
            });
        }
        return {};
      }
    });
  }

  async updateUserAdjustRulesByIdDepatamentAndIdCargo(
    id,
    PortalID,
    uidDepartamentoAnterior,
    uidCargoColaboradorAnterior,
    uidDepartamento,
    uidCargoColaborador
  ) {
    PortalID = await this.getPortalId(PortalID);
    return await getDoc(doc(db, "users", id))
      .then(async (userdoc) => {
        if (userdoc.exists()) {
          let user = userdoc.data();
          await user.usersgroupsowned.map(async (group, indxGroup) => {
            if (group.portalID === PortalID) {
              return await getDoc(doc(db, "departamentos", uidDepartamentoAnterior))
                .then(async (departamentoAnteriordoc) => {
                  if (departamentoAnteriordoc.exists()) {
                    let departamento = departamentoAnteriordoc.data();
                    await departamento.cargos.map(async (cargo) => {
                      if (cargo.uid === uidCargoColaboradorAnterior) {
                        let newRules = [];
                        cargo.rulesOwned = await cargo.rulesOwned.map(async (rule) => {
                          newRules = group.groupsOwned.filter((groupOwned) => {
                            return groupOwned !== rule;
                          });
                        });
                        group.groupsOwned = [...newRules];
                      }
                    });
                    return await getDoc(doc(db, "departamentos", uidDepartamento))
                      .then(async (departamentodoc) => {
                        if (departamentodoc.exists()) {
                          let departamento = departamentodoc.data();
                          departamento.cargos.map(async (cargo) => {
                            if (cargo.uid === uidCargoColaborador) {
                              group.groupsOwned = [...group.groupsOwned, ...cargo.rulesOwned];
                              user.usersgroupsowned[indxGroup] = group;
                              Promise.all(user.usersgroupsowned).then(async (newUsersgroupsowned) => {
                                return await updateDoc(doc(db, "users", id), { usersgroupsowned: newUsersgroupsowned }, { merge: true })
                              });
                            }
                          });

                        }
                      })
                      .catch((error) => {
                        throw error;
                      });
                  }
                })
                .catch((error) => {
                  throw error;
                });
            }
            return group;
          });
          return {};
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  async getPortalId(portalID) {
    if (portalID === undefined || portalID === "") {
      return portalID;
    } else {
      // return portalID;
      return await getDoc(doc(db, "entidades", portalID))
        .then(async (entidadedoc) => {
          if (entidadedoc.exists()) {
            // console.log("entidadedoc.data().dadosPessoais.tipoEntidade=", entidadedoc.data().dadosPessoais.tipoEntidade);
            // console.log("entidadedoc.data().dadosFiliacao=", entidadedoc.data().dadosFiliacao);
            // console.log("entidadedoc.data().dadosFiliacao.federacaoID=", entidadedoc.data().dadosFiliacao.federacaoID);
            if (entidadedoc.data().dadosPessoais.tipoEntidade === "Confederação") return portalID;
            else if (entidadedoc.data().dadosPessoais.tipoEntidade === "Federação") return portalID;
            else if (entidadedoc.data().dadosFiliacao.federacaoID === undefined)
              return await this.getPortalIdBySigla(entidadedoc.data().dadosFiliacao.federacao);
            return entidadedoc.data().dadosFiliacao.federacaoID;
          }
          else return portalID;
        }).catch((error) => {
          console.log(error);
          return portalID;
        });
    }
  }

  async getPortalIdBySigla(siglaEntidade) {
    console.log("getPortalIdBySigla(siglaEntidade=", siglaEntidade, ")");
    if (siglaEntidade === undefined || siglaEntidade === "") {
      return undefined;
    }
    return await GenericVinculadoService.getCollectionByConditions("entidades", [
      where("dadosPessoais.sigla", "==", siglaEntidade),
      where("metadados.successorId", "==", ""),
      where("metadados.active", "==", true),
      orderBy("metadados.creation", "desc")])
      .then((querySnapshot) => {
        console.log("querySnapshot=", querySnapshot);
        if (querySnapshot[0].length > 0) {
          console.log("querySnapshot[0][0].id=", querySnapshot[0][0].id);
          return querySnapshot[0][0].id;
        }
        return undefined;
      }).catch((error) => {
        console.log(error);
        return undefined;
      });
  }


  updateView = async (id) => {
    try {
      GenericService.getOne("praticantes", id).then(async (value) => {
        let newViewData = {
          id: id,
          imageURL: value.dadosPessoais.urlImagemID ? value.dadosPessoais.urlImagemID : "",
          dadosPessoais: {
            name: value.dadosPessoais.name,
            searchableName: value.dadosPessoais.name.toLowerCase(),
            trigam: generateTrigam(value.dadosPessoais.name),
          },
          MaxGraduacao: value.dadosTaekwondo.MaxGraduacao,
          uf: value.dadosEndereco.logradouroUF,
          birthdate: value.dadosPessoais.birthdate,
          sexo: value.dadosPessoais.sexo ? value.dadosPessoais.sexo : "Não informado",
          filiacao: value.dadosFiliacao.federacao,
          federacaoID: value.dadosFiliacao.federacaoID,
          vinculadoEntidadeID: value.dadosFiliacao.vinculadoEntidadeID ? value.dadosFiliacao.vinculadoEntidadeID : "",
          metadados: { active: value.metadados.active, version: 1 },
        };

        GenericService.getOne("praticantes.view", id).then((retData) => {
          if (retData) {
            updateDoc(doc(db, "praticantes.view", id), newViewData);
          } else {
            setDoc(doc(db, "praticantes.view", id), newViewData);
          }
        });
      }).catch((error) => {
        throw error;
      });
    } catch (error) {
      throw error;
    }
  };

}

export default new praticantesService();
