import React from "react";

let message = "O sistema está ocupado no momento.";

export function hideSpinner() {
  const modal = document.getElementById("loadMe");
  // //console.log("hideSpinner antes => ", modal);
  if (modal) {
    if (modal.classList !== undefined && modal.classList !== null) {
      if (modal.classList.contains("show")) modal.classList.remove("show");
    }
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");
  }
  // //console.log("hideSpinner depois => ", modal);
}

export function showSpinner(alertMessage) {
  if (alertMessage !== undefined && alertMessage !== null && alertMessage !== "") message = alertMessage;
  // setTimeout(() => {
  const modal = document.getElementById("loadMe");
  // //console.log("showSpinner antes => ", modal);
  if (modal) {
    if (modal.classList !== undefined && modal.classList !== null) {
      if (!modal.classList.contains("show")) modal.classList.add("show");
    }
    modal.setAttribute("aria-hidden", "false");
    modal.setAttribute("style", "display: block");
  }
  // }, 300);
  // //console.log("showSpinner depois => ", modal);
}

const BusySpinner = () => {
  return (
    <>
      <div
        className="modal fade bs-modal-darness"
        id="loadMe"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="loadMeLabel"
      // aria-hidden="true"
      >
        <div className="modal-dialog modal-sm bs-modal-vertical-middle" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="loader"></div>
              <div clas="loader-txt">
                Aguarde... <br /> <br />
                <div id="spinner" className="spinner-border text-primary" role="status"></div>
                <br />
                <br />
                <small>{message}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusySpinner;
