import React, { Fragment } from "react";
// import EntidadesService from "../../services/Entidades.Service";
// import certificationService from "../../services/certification.service";
import BusySpinner, { showSpinner, hideSpinner } from "../BusySpinner";
import { createKey } from "../../utils/KeysUtil";
// import { formatDataByType } from "../../utils/FormatDataTypesUtils";
import QRCode from "react-qr-code";
// import { getUrlDetails } from "../../utils/UrlDomainUtil";
import { format } from "date-fns";
// import { getMonth } from "../../utils/monthUtil";
// import contasService from "../../services/contas.service";
import GenericService from "../../services/Generic.service";
import { Timestamp } from "firebase/firestore";
import { getMonth } from "../../utils/monthUtil";
import "../..//styles/Certificacao.css";
import { maxGraduacaoDescription } from "../../utils/GraduacoesUtil";

class PrintCertificadoComponent extends React.Component {
  state = {
    error: "",
    certificado: undefined,
    federacao: undefined,
  };
  // queryParams = new URLSearchParams(window.location.search);

  componentDidMount() {
    this.loadData();
  }

  getGraduacaoFromDescricao = (descricao) => {
    let graduacao = 0;
    if (descricao.indexOf("DAN") !== -1) {
      descricao.split(" ").map((item) => {
        if (item.indexOf("º") !== -1) {
          graduacao = parseInt(item.split("º")[0]);
          graduacao += 10;
        }
      });
    }
    else if (descricao.indexOf("GUB") !== -1) {
      descricao.split(" ").map((item) => {
        if (item.indexOf("º") !== -1) {
          graduacao = parseInt(item.split("º")[0]);
          graduacao = 11 - graduacao;
        }
      });
    }
    return graduacao;
  }

  getIndxGraduacaoAlvo = async (descricao) => {
    console.log("descricao=", descricao);
    if (descricao.toUpperCase().indexOf("DAN") === -1) return 0;
    if (descricao.toUpperCase().indexOf("REGISTRO") === -1) return 0;
    if (descricao.toUpperCase().indexOf("KUKKIWON") !== -1) return 0;
    return await GenericService.getOne("services", this.props.doc.idServico)
      .then((service) => {
        if (service) {
          console.log("service=", service);
          let graduacaoAlvo = service.certificado.nivel.toUpperCase().replace("DAN", "").replace("º", "").trim();
          console.log("graduacaoAlvo=", graduacaoAlvo, Number(Number(graduacaoAlvo) + 10));
          return Number(Number(graduacaoAlvo) + 10);
        } else {
          return 0;
        }
      }).catch((err) => {
        console.log(err);
        this.props.setError(err.message);
        return 0;
      });
  };

  loadData = async () => {
    showSpinner();
    // await contasService
    //   .getOne(this.queryParams.get("id"))
    //   .then(async (docs) => {      
    if (this.props.doc) {
      let receivedDoc = this.props.doc;
      if (receivedDoc.dadosCertificacao !== undefined) {
        await GenericService.getOne("praticantes", receivedDoc.vinculo.idEntidade)
          .then(async (praticante) => {
            if (praticante) {
              let indxGraduacaoAlvo = 0;
              console.log("receivedDoc.dadosCertificacao.graduacao=", receivedDoc.dadosCertificacao.graduacao, "receivedDoc.servico=", receivedDoc.servico, "praticante.dadosTaekwondo.historicoGraduacoes=", praticante.dadosTaekwondo.historicoGraduacoes)
              if (receivedDoc.dadosCertificacao.graduacao === undefined || receivedDoc.dadosCertificacao.graduacao === "") {
                let service = await GenericService.getOne("services", receivedDoc.servico);
                // await GenericService.getOne("services", props.doc.servico).then((service) => {
                if (service)
                  indxGraduacaoAlvo = service.certificado.nivel.toUpperCase().indexOf("DAN") !== -1 ? (10 + Number(service.certificado.nivel.split(" ")[0])) : (11 - Number(service.certificado.nivel.split(" ")[0]));
                else
                  indxGraduacaoAlvo = this.getGraduacaoFromDescricao(receivedDoc.descricao);
                // indxGraduacaoAlvo = receivedDoc.dadosCertificacao.graduacao //await this.getIndxGraduacaoAlvo(receivedDoc.descricao);
              } else {
                // indxGraduacaoAlvo = receivedDoc.dadosCertificacao.graduacao.toUpperCase().indexOf("DAN") !== -1 ? (10 + Number(receivedDoc.dadosCertificacao.graduacao.split(" ")[0])) : (11 - Number(receivedDoc.dadosCertificacao.graduacao.split(" ")[0]));
                indxGraduacaoAlvo = receivedDoc.dadosCertificacao.graduacao;
              }
              console.log("indxGraduacaoAlvo=", indxGraduacaoAlvo, "maxGraduacaoDescription(indxGraduacaoAlvo)=", maxGraduacaoDescription(indxGraduacaoAlvo));
              // console.log("praticante.dadosTaekwondo.historicoGraduacoes=", praticante.dadosTaekwondo.historicoGraduacoes);
              // console.log("praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data=", praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data);
              console.log("praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data=", praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data);
              if (praticante.dadosTaekwondo && praticante.dadosTaekwondo.historicoGraduacoes) {
                let dataGraduacao = "";
                if (typeof (praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data) === "string")
                  dataGraduacao = new Date(praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data + "T03:00:00");
                else
                  dataGraduacao = new Date(format(praticante.dadosTaekwondo.historicoGraduacoes[indxGraduacaoAlvo - 1].data.toDate(), "yyyy-MM-dd") + "T03:00:00");
                // console.log("dataGraduacao=", dataGraduacao);
                receivedDoc = {
                  ...receivedDoc,
                  dadosCertificacao: {
                    ...receivedDoc.dadosCertificacao,
                    // idServico: "", // Era idCertificado: newData.idCertificado, //é o mesmo que o id do serviço (Taxa). Não necessário
                    // numero: "",
                    // dataEmissao: Timestamp.fromDate(new Date()),
                    dataGraduacao: Timestamp.fromDate(dataGraduacao),
                    graduacao: maxGraduacaoDescription(indxGraduacaoAlvo),
                    // dataCarencia: "", //props.dtCarencia,
                    // dataValidade: "",
                    // orgaoEmissor: "",
                    // urlFrente: "",
                    // urlVerso: "",
                    // fazerEmissao: false,
                    // graduacao: "",
                  },
                };
              }
            }
          }).catch((err) => {
            console.log(err);
            this.setState((prevState) => ({ ...prevState, error: err.message }));
          });
      } else if (receivedDoc.dadosCertificacao.dataEmissao === undefined) {
        receivedDoc.dadosCertificacao.dataEmissao = Timestamp.fromDate(new Date());
      }
      if (receivedDoc.dadosCertificacao.graduacao === undefined || receivedDoc.dadosCertificacao.graduacao === "") {
        receivedDoc.dadosCertificacao.graduacao = "1º DAN";
      }
      GenericService.getOne("praticantes", receivedDoc.vinculo.idEntidade).then((praticante) => {
        console.log("praticante=", praticante, "praticante.dadosFiliacao.federacaoID:", praticante.dadosFiliacao.federacaoID);
        GenericService.getOne("entidades", praticante.dadosFiliacao.federacaoID).then((federacao) => {
          this.setState((prevState) => ({
            ...prevState,
            certificado: { ...receivedDoc, numero: praticante.matriculaCBTKD },
            federacao: praticante.dadosFiliacao !== undefined ? federacao.dadosPessoais.name : ""
          }));
        }).catch((err) => {
          console.log(err);
          this.setState((prevState) => ({
            ...prevState,
            certificado: receivedDoc,
            federacao: praticante.dadosFiliacao !== undefined ? praticante.dadosFiliacao.federacao : "",
            error: err.message
          }));
        });
      }).catch((err) => {
        console.log(err);
        this.setState((prevState) => ({ ...prevState, error: err.message }));
      });

      hideSpinner();
    }
    hideSpinner();
    // })
    // .catch((err) => {
    //   //console.log(err);
    //   this.setState((prevState) => ({ ...prevState, error: err.message }));
    //   hideSpinner();
    // });
  };

  extraClassName = "";



  render() {
    console.log("render()");
    console.log("this.state.certificado=", this.state.certificado ? this.state.certificado : "undefined");
    return (
      < >
        {(this.state.certificado && this.state.federacao) ? (
          <Fragment key={createKey()}>
            <div
              // className="m-4 card p-4 certificado_bgimg"
              className="p-0 ms-0 mt-0 mb-0 me-0"
              // style={{ minHeight: "957px", maxHeight: "957px", minWidth: "1354px", maxWidth: "1354px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FCBTKD_certificado_modelo1_branco.png?alt=media&token=64e30a70-abbf-48b4-b4d2-e47a3f1706e5)" }}
              // style={{ minHeight: "957px", maxHeight: "957px", minWidth: "1354px", maxWidth: "1354px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FModelo_Certificado_CBTKD.jpeg?alt=media&token=bd062c53-c056-4e6f-b459-9b8f545d7431)" }}
              style={{
                minHeight: "985px", maxHeight: "985px", minWidth: "1392px", maxWidth: "1392px",
                backgroundImage: this.props.noBackground ? "" : "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FModelo_Certificado_CBTKD_MOD_Rivanaldo.png?alt=media&token=10a5a155-9238-451b-a6ba-15ce0d40250e)",
                // backgroundImage: this.props.noBackground ? "" : "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FModelo_Certificado_CBTKD.jpeg?alt=media&token=bd062c53-c056-4e6f-b459-9b8f545d7431)",
                // backgroundImage: this.props.noBackground ? "" : "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FModelo_Certificado_CBTKD_MOD3.png?alt=media&token=7f83efe6-1563-42ef-bccc-cb2ca3f51570)",
                backgroundRepeat: "no-repeat",
                // backgroundSize: "1223px 865px",
                backgroundColor: this.props.noBackground ? "white" : "gray",
                // backgroundSize: "1278px 904px",
                backgroundSize: "cover",
                transform: this.props.noBackground ? "" : "transformY(100px)"
                // scale: "0.54",
              }}
            >
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black fs-4 fw-bold mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "558px", marginTop: this.props.noBackground ? "402px" : "427px", minWidth: "735px", maxWidth: "735px" }}>
                  {this.state.certificado.vinculo.name.toUpperCase()}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "1115px", marginTop: "14px", minWidth: "80px", maxWidth: "80px", fontSize: "18px" }}>
                  {this.state.certificado.dadosCertificacao.graduacao !== undefined && this.state.certificado.dadosCertificacao.graduacao !== "" && this.state.certificado.dadosCertificacao.graduacao.toUpperCase()}
                  {/* 1º DAN */}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap" style={{ marginTop: "-38px" }}>
                <div
                  className={`text-center col text-black mb-4 ps-2 ${this.extraClassName}`}
                  style={{ marginLeft: "760px", marginTop: "34px", minWidth: "165px", maxWidth: "165px", fontSize: "16px" }}
                >
                  {this.state.certificado.numero}
                </div>
                <div
                  className={`text-center col text-black mb-4 ps-2 ${this.extraClassName}`}
                  style={{ marginTop: "34px", minWidth: "185px", maxWidth: "185px" }}
                ></div>
                <div className={`text-center col text-black mb-4 pe-4 ${this.extraClassName}`} style={{ marginLeft: "15px", marginTop: "34px", minWidth: "185px", maxWidth: "185px", fontSize: "16px" }}>
                  {this.state.certificado.dadosCertificacao.dataGraduacao && this.state.certificado.dadosCertificacao.dataGraduacao !== "" && format(this.state.certificado.dadosCertificacao.dataGraduacao.toDate(), "dd/MM/yyyy")}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "585px", marginTop: "6px", minWidth: "710px", maxWidth: "710px", fontSize: "16px" }}>
                  {this.state.federacao.toUpperCase()}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap" >
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={this.props.noBackground ? { marginLeft: "707px", marginTop: "18px", minWidth: "84px", maxWidth: "84px", fontSize: "16px" } : { marginLeft: "675px", marginTop: "18px", minWidth: "84px", maxWidth: "84px", fontSize: "16px" }}
                >
                  {this.state.certificado.dadosCertificacao.dataEmissao !== "" && format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "dd")}
                </div>
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={this.props.noBackground ? { marginLeft: "18px", marginTop: "18px", minWidth: "115px", maxWidth: "115px", fontSize: "16px" } : { marginLeft: "30px", marginTop: "18px", minWidth: "115px", maxWidth: "115px", fontSize: "16px" }}
                >
                  {this.state.certificado.dadosCertificacao.dataEmissao !== "" && getMonth(format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "M")).toUpperCase()}
                </div>
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={this.props.noBackground ? { marginLeft: "8px", marginTop: "18px", minWidth: "152px", maxWidth: "152px", fontSize: "16px" } : { marginLeft: "25px", marginTop: "18px", minWidth: "152px", maxWidth: "152px", fontSize: "16px" }}
                >
                  {this.state.certificado.dadosCertificacao.dataEmissao !== "" && format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "yyyy")}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className="col border border-1 p-2" style={{ marginLeft: "1237px", marginTop: "95px", minWidth: "126px", maxWidth: "126px", transform: "translateY(-752px)" }}>
                  <QRCode value={window.location.href.replace("/sie/servicos/certificados", "/validacao/certificado?id=" + this.props.docId)} size={108} />
                </div>
              </div>
            </div>
          </Fragment >
        ) : (
          ""
        )
        }
        <BusySpinner />
      </>
    );
  }
}

export default PrintCertificadoComponent;
