import { db } from "../utils/firestore";
import {
  // doc,
  // getDoc,
  collection,
  // addDoc,
  getDocs,
  // updateDoc,
  // deleteDoc,
  query,
  orderBy,
  where,
  startAfter,
  endBefore,
  limit,
  limitToLast,
  // setDoc,
  getCountFromServer,
} from "firebase/firestore";
import { generateTrigam } from "../utils/trigamUtils";
// import { CommentsDisabled } from "@mui/icons-material";
// import { hasAllArrayElements } from "../utils/arrayUtils";
import { keepOrderIfNotDuplicated } from "../utils/conditionsUtils";
// import { Timestamp } from "firebase/firestore";
import GenericService from "./Generic.service";

//const dbCollection = db.collectionName("/contasgroups");

class GenericVinculadoService {
  //Implementado para evitar quebra dos servicos
  updateActiveState = async (collectionName, id, newStateValue, userId, userName) => {
    return await GenericService.updateActiveState(collectionName, id, newStateValue, userId, userName);
  };

  updateFederativeActiveState = async (collectionName, id, newStateValue, userId, userName) => {
    return await GenericService.updateFederativeActiveState(collectionName, id, newStateValue, userId, userName);
  };

  getAll = async (collectionName, orderby, ascDesc, showAllVersions, vinculo_idEntidade, vinculoTarget) => {
    // //console.log("GenericService.getAll(", collectionName, orderby, ascDesc, vinculo_idEntidade, ")");
    const conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    conditions.push(orderBy(orderby, ascDesc));
    // if (pageSize !== null) conditions.push(limit(pageSize));
    // if (firstVisible !== null) conditions.push(endBefore(firstVisible));
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  getCollectionSize = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    operation,
    show_1_Active_2_Inactive_3_Both,
    associacaoIdTarget,
    associacaoTarget,
    showBloqueados
  ) => {

    // console.log("getCollectionSize(",
    // "collectionName:", collectionName,
    // "queryTarget:", queryTarget,
    // "queryText:", queryText,
    // "firstVisible:", firstVisible,
    // "lastVisible:", lastVisible,
    // "pageSize:", pageSize,
    // "orderby:", orderby,
    // "ascDesc:", ascDesc,
    // "showAllVersions:", showAllVersions,
    // "vinculo_idEntidade:", vinculo_idEntidade,
    // "vinculoTarget:", vinculoTarget,
    // "operation:", operation,
    // "show_1_Active_2_Inactive_3_Both:", show_1_Active_2_Inactive_3_Both,
    // "associacaoIdTarget:", associacaoIdTarget,
    // "associacaoTarget:", associacaoTarget,
    // "showBloqueados:", showBloqueados,
    // ")");

    if (lastVisible === undefined) lastVisible = null;
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    // //console.log("getCollectionSize => show_1_Active_2_Inactive_3_Both: ", show_1_Active_2_Inactive_3_Both);
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    if (operation !== undefined && typeof queryTarget === "string" && (typeof queryText === "object" || typeof queryText === "string"))
      conditions.push(where(queryTarget, operation, queryText));
    else conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];

    if (associacaoIdTarget) {
      conditions.push(where("dadosFiliacao.vinculadoAEntidadeID", "==", associacaoIdTarget));
    }
    else if (associacaoTarget) {
      conditions.push(where("dadosFiliacao.vinculadoAEntidade", "==", associacaoTarget));
    }

    // conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    if (show_1_Active_2_Inactive_3_Both !== 3) conditions.push(where("metadados.active", "==", show_1_Active_2_Inactive_3_Both === 1));
    if (showBloqueados) conditions.push(where("metadados.blocked", "==", true));
    // if (pageSize !== null) conditions.push(limit(pageSize));
    // if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // if (orderby !== "") conditions.push(orderBy(orderby, ascDesc));
    // if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    // if (lastVisible !== null && lastVisible != "") conditions.push(startAfter(lastVisible));
    // let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // return querySnapshot.docs.length;
    // console.log("getCollectionSize => conditions: ", conditions);
    const q = query(collection(db, collectionName), ...conditions);
    const snapshot = await getCountFromServer(q);
    // //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getCollectionSizeByComplexFilter = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    operation
  ) => {
    //   return await this.getCollectionSearchByPrefix(
    //     collectionName,
    //     queryTarget,
    //     queryText,
    //     null,
    //     null,
    //     null,
    //     orderby,
    //     ascDesc,
    //     showAllVersions,
    //     vinculo_idEntidade,
    //     vinculoTarget,
    //     operation
    // )[1];

    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }

    if (queryText !== "") {
      if (queryText.searchInfo !== undefined) {
        if (queryText.searchInfo !== "") {
          if (queryText.searchInfo.indexOf("trigam") > -1) {
            conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText.searchInfo)]));
          } else {
            conditions.push(where(queryTarget, ">=", queryText.searchInfo));
            conditions.push(where(queryTarget, "<=", queryText.searchInfo + "\uf8ff"));
          }
        }
      }
      let queryTextArray = Object.entries(queryText);
      queryTextArray.map((item) => {
        if (item[0] !== "searchInfo")
          if (!["", "Todos", "Todas", "Selecione"].includes(item[1])) conditions.push(where(item[0], "==", item[1]));
      });
    }

    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    console.log("getCollectionSizeByComplexFilter() => conditions=", conditions);
    let snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions));
    return snapshot.data().count;
  };

  getCollectionSizeBySpecialConditions = async (
    collectionName,
    searchConditions,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    operation
  ) => {

    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    // //console.log("getCollectionSizeByComplexFilter => conditions: ", conditions);

    searchConditions.map((searchConditionItem) => {
      conditions.push(where(searchConditionItem.field, searchConditionItem.operator, searchConditionItem.value));
    });
    // //console.log("getCollectionSizeByComplexFilter => conditions: ", conditions);

    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));

    // console.log("getCollectionSizeByComplexFilter => collectionName:", collectionName, "conditions: ", conditions);
    const q = query(collection(db, collectionName), ...conditions);
    const snapshot = await getCountFromServer(q);
    // //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getFilteredCollection = async (
    collectionName,
    filterTarget,
    operation,
    filterValue,
    firstVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both
  ) => {
    // //console.log(
    //   "getFilteredCollection(collectionName:",
    //   collectionName,
    //   "filterTarget:",
    //   filterTarget,
    //   "operation:",
    //   operation,
    //   "filterValue:",
    //   filterValue,
    //   "firstVisible:",
    //   firstVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "showAllVersions:",
    //   showAllVersions,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   "show_1_Active_2_Inactive_3_Both:",
    //   show_1_Active_2_Inactive_3_Both,
    //   ")"
    // );
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    // //console.log();
    // //console.log("conditions=", conditions);
    // //console.log("filterTarget=", filterTarget);
    // //console.log("filterValue=", filterValue);
    // //console.log("typeof filterTarget=", typeof filterTarget);
    // //console.log("typeof filterValue=", typeof filterValue);

    if (
      typeof filterTarget === "string" &&
      (typeof filterValue === "object" || typeof filterValue === "string" || typeof filterValue === "number")
    )
      conditions.push(where(filterTarget, operation, filterValue));
    else conditions = [...conditions, ...this.getConditionsFromQueryFilter(filterTarget, filterValue)];
    // if (filterValue && filterValue !== "") conditions.push(where(filterTarget, operation, filterValue));

    // //console.log("getFilteredCollection => conditions=", conditions);

    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    if (show_1_Active_2_Inactive_3_Both === 1) conditions.push(where("metadados.active", "==", true));
    else if (show_1_Active_2_Inactive_3_Both === 2) conditions.push(where("metadados.active", "==", false));
    // //console.log("getFilteredCollection => conditions=", conditions);

    if (pageSize !== null) conditions.push(limit(pageSize));
    // if (orderby !== null && orderby !== "") conditions.push(orderBy(orderby, ascDesc));
    if (orderby !== null) {
      if (orderby !== "") {
        if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
        else conditions.push(orderBy(orderby, ascDesc));
      } else conditions.push(orderBy(orderby, ascDesc));
    }
    // if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));

    if (firstVisible !== null && firstVisible !== "") conditions.push(startAfter(firstVisible));
    // if (lastVisible !== null && lastVisible !== "") conditions.push(endBefore(lastVisible));


    conditions = keepOrderIfNotDuplicated(conditions);

    // if (collectionName === "agendamentos") {
    //   console.clear();
    //   // //console.log("typeof filterValue = ", typeof filterValue);
    //   // //console.log(
    //     "getFilteredCollection(collectionName:",
    //     collectionName,
    //     "filterTarget:",
    //     filterTarget,
    //     "operation:",
    //     operation,
    //     "filterValue:",
    //     filterValue,
    //     "firstVisible:",
    //     firstVisible,
    //     "pageSize:",
    //     pageSize,
    //     "orderby:",
    //     orderby,
    //     "ascDesc:",
    //     ascDesc,
    //     "showAllVersions:",
    //     showAllVersions,
    //     "vinculo_idEntidade:",
    //     vinculo_idEntidade,
    //     "vinculoTarget:",
    //     vinculoTarget,
    //     ")"
    //   );
    // //console.log("getFilteredCollection(", collectionName, ") => conditions=", conditions);
    // }
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // //console.log("getFilteredCollection(", collectionName, ") with conditions=", conditions, " => querySnapshot.docs=", querySnapshot.docs);
    let collectionSize = await this.getCollectionSize(
      collectionName,
      filterTarget,
      filterValue,
      firstVisible,
      null,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget,
      operation,
      show_1_Active_2_Inactive_3_Both
    );
    // //console.log("getFilteredCollection(", collectionName, ") => collectionSize=", collectionSize);
    // //console.log("getFilteredCollection =>", querySnapshot.docs, "size:", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchByPrefix = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both,
    associacaoIdTarget,
    associacaoTarget,
    showBloqueados
  ) => {
    // console.log(
    //   "getCollectionSearchByPrefix(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "showAllVersions:",
    //   showAllVersions,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   "show_1_Active_2_Inactive_3_Both:",
    //   show_1_Active_2_Inactive_3_Both,
    //   "associacaoIdTarget:",
    //   associacaoIdTarget,
    //   "associacaoTarget:",
    //   associacaoTarget,
    //   "showBloqueados:",
    //   showBloqueados,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    // //console.log("getCollectionSearchByPrefix() => show_1_Active_2_Inactive_3_Both=", show_1_Active_2_Inactive_3_Both);
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];
    if (associacaoIdTarget) {
      conditions.push(where("dadosFiliacao.vinculadoAEntidadeID", "==", associacaoIdTarget));
    }
    else if (associacaoTarget) {
      conditions.push(where("dadosFiliacao.vinculadoAEntidade", "==", associacaoTarget));
    }
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions && !showBloqueados) conditions.push(where("metadados.successorId", "==", ""));
    // //console.log("getCollectionSearchByPrefix() => show_1_Active_2_Inactive_3_Both=", show_1_Active_2_Inactive_3_Both);
    if (show_1_Active_2_Inactive_3_Both === 1) conditions.push(where("metadados.active", "==", true));
    else if (show_1_Active_2_Inactive_3_Both === 2) conditions.push(where("metadados.active", "==", false));
    // console.log("getCollectionSearchByPrefix() => showBloqueados=", showBloqueados);
    if (showBloqueados) conditions.push(where("metadados.blocked", "==", true));
    // if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    if (queryTarget !== orderby && queryText !== "") {
      conditions.push(orderBy(queryTarget, ascDesc));
    }
    conditions = keepOrderIfNotDuplicated(conditions);
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);
    if (firstVisible !== null && firstVisible !== "") conditions.push(startAfter(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(endBefore(lastVisible));
    // if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    // if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    // console.log("getCollectionSearchByPrefix() => conditions=", conditions, "collectionName=", collectionName);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    //console.log("getCollectionSearchByPrefix() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSize(
      collectionName,
      queryTarget,
      queryText,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget,
      undefined,
      show_1_Active_2_Inactive_3_Both,
      associacaoIdTarget,
      associacaoTarget,
      showBloqueados
    );
    //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchByPrefixWithComplexFilter = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    //console.log(
    //   "getCollectionSearchByPrefixWithComplexFilter(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }

    // if (queryText !== "") {
    //   if (queryText.searchInfo !== "") {
    //     if (queryText.searchInfo.indexOf("trigam") > -1) {
    //       conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText.searchInfo)]));
    //     } else {
    //       conditions.push(where(queryTarget, ">=", queryText.searchInfo));
    //       conditions.push(where(queryTarget, "<=", queryText.searchInfo + "\uf8ff"));
    //     }
    //   }
    //   let queryTextArray = Object.entries(queryText);
    //   queryTextArray.map((item) => {
    //     if (item[0] !== "searchInfo")
    //       if (!["", "Todos", "Todas", "Selecione"].includes(item[1])) conditions.push(where(item[0], "==", item[1]));
    //   });
    // }

    conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText.searchInfo)];
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => queryTarget=", queryTarget, "queryText=", queryText);
    if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => orderby=", orderby);
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);
    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    //console.log("getCollectionSearchByPrefixWithComplexFilter() => conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSizeByComplexFilter(
      collectionName,
      queryTarget,
      queryText,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget
    );
    // //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchByPrefixWithComplexFilter_version2 = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    // console.log(
    //   "getCollectionSearchByPrefixWithComplexFilter_version2(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }

    // if (queryText !== "") {
    //   if (queryText.searchInfo !== "") {
    //     if (queryText.searchInfo.indexOf("trigam") > -1) {
    //       conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText.searchInfo)]));
    //     } else {
    //       conditions.push(where(queryTarget, ">=", queryText.searchInfo));
    //       conditions.push(where(queryTarget, "<=", queryText.searchInfo + "\uf8ff"));
    //     }
    //   }
    //   let queryTextArray = Object.entries(queryText);
    //   queryTextArray.map((item) => {
    //     if (item[0] !== "searchInfo")
    //       if (!["", "Todos", "Todas", "Selecione"].includes(item[1])) conditions.push(where(item[0], "==", item[1]));
    //   });
    // }

    conditions = [...conditions, ...this.getConditionsFromQueryFilter_version2(queryTarget, queryText)];
    //console.log("getCollectionSearchByPrefixWithComplexFilter_version2() => conditions=", conditions);
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => queryTarget=", queryTarget, "queryText=", queryText);
    if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => orderby=", orderby);
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);
    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    console.log("getCollectionSearchByPrefixWithComplexFilter() => conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // //console.log("getCollectionSearchByPrefixWithComplexFilter() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSizeByComplexFilter(
      collectionName,
      queryTarget,
      queryText,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget
    );
    // //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchByPrefixBySpecialConditions = async (
    collectionName,
    queryTarget,
    queryText,
    searchConditions,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    // //console.log(
    //   "getCollectionSearchByPrefix(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "searchConditions",
    //   searchConditions,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];

    searchConditions.map((searchConditionItem) => {
      // //console.log("searchConditionItem=", searchConditionItem);
      conditions.push(where(searchConditionItem.field, searchConditionItem.operator, searchConditionItem.value));
    });

    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    if (queryTarget !== orderby && queryText !== "") {
      conditions.push(orderBy(queryTarget, ascDesc));
    }
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);
    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    // //console.log("getCollectionSearchByPrefix() => conditions=", conditions, "collectionName=", collectionName);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // //console.log("getCollectionSearchByPrefix() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSize(
      collectionName,
      queryTarget,
      queryText,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget
    );
    // //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchBySpecialConditions = async (
    collectionName,
    searchConditions,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both
  ) => {
    // console.log(
    //   "getCollectionSearchBySpecialConditions(collectionName:",
    //   collectionName,
    //   "searchConditions:",
    //   searchConditions,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   "show_1_Active_2_Inactive_3_Both:",
    //   show_1_Active_2_Inactive_3_Both,
    //   ")"
    // );

    if (lastVisible === undefined) lastVisible = null;
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = [];

    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    // //console.log("getCollectionSearchBySpecialConditions() => conditions=", conditions);

    searchConditions.map((searchConditionItem) => {
      // //console.log("searchConditionItem=", searchConditionItem);
      conditions.push(where(searchConditionItem.field, searchConditionItem.operator, searchConditionItem.value));
    });
    // //console.log("getCollectionSearchBySpecialConditions() => conditions=", conditions);

    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    if (show_1_Active_2_Inactive_3_Both === 1) conditions.push(where("metadados.active", "==", true));
    else if (show_1_Active_2_Inactive_3_Both === 2) conditions.push(where("metadados.active", "==", false));
    // //console.log("getCollectionSearchBySpecialConditions() => conditions=", conditions);
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchBySpecialConditions() => conditions=", conditions);

    let orderbyItens = [];

    // //console.log("getCollectionSearchBySpecialConditions() => orderby=", orderby);

    if (orderby) {
      if (orderby !== "") {
        if (Array.isArray(orderby))
          orderby.map((item) => {
            if (!orderbyItens.includes(item)) {
              conditions.push(orderBy(item));
              orderbyItens.push(item);
            }
          });
        else conditions.push(orderBy(orderby, ascDesc));
      }
    }
    conditions = keepOrderIfNotDuplicated(conditions);
    // //console.log("getCollectionSearchBySpecialConditions() => conditions=", conditions);
    // //console.log("getCollectionSearchBySpecialConditions() => firstVisible=", firstVisible);
    // //console.log("getCollectionSearchBySpecialConditions() => lastVisible=", lastVisible);

    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));

    // conditions.splice(6, 2);
    // conditions.splice(5, 1);
    // conditions.splice(4, 1);
    // conditions.splice(3, 1);
    // conditions.splice(2, 1);
    // conditions.splice(1, 1);
    // conditions.splice(1, 4);
    // conditions.splice(5, 1);

    // console.log("getCollectionSearchBySpecialConditions() => collectionName=", collectionName, "conditions=", conditions);

    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    // console.log("getCollectionSearchBySpecialConditions() => querySnapshot=", querySnapshot);
    // console.log("getCollectionSearchBySpecialConditions() => querySnapshot.docs=", querySnapshot.docs);
    // console.log("getCollectionSearchBySpecialConditions() => querySnapshot.docs.length=", querySnapshot.docs.length);

    let collectionSize = await this.getCollectionSizeBySpecialConditions(
      collectionName,
      searchConditions,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget
    );
    // //console.log("getCollectionSearchBySpecialConditions() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSearchByPartialWithoutSize = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    // //console.log(
    //   "getCollectionSearchByPrefix(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchByPartialWithoutSize() => queryTarget=", queryTarget, "queryText=", queryText);
    if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // //console.log("getCollectionSearchByPartialWithoutSize() => orderby=", orderby);
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);

    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    // //console.log("getCollectionSearchByPartialWithoutSize() => conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  getCollectionSearchByPrefixWithoutSize = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both
  ) => {
    // //console.log(
    //   "getCollectionSearchByPrefix(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];
    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    if (show_1_Active_2_Inactive_3_Both === 1) conditions.push(where("metadados.active", "==", true));
    else if (show_1_Active_2_Inactive_3_Both === 2) conditions.push(where("metadados.active", "==", false));
    // if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchByPrefix() => queryTarget=", queryTarget, "queryText=", queryText);
    if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // //console.log("getCollectionSearchByPrefix() => orderby=", orderby);
    if (orderby !== "") {
      if (Array.isArray(orderby))
        orderby.map((item) => {
          if (!conditions.includes(orderBy(item))) conditions.push(orderBy(item));
        });
      else {
        if (!conditions.includes(orderBy(orderby, ascDesc))) conditions.push(orderBy(orderby, ascDesc));
        // conditions.push(orderBy(orderby, ascDesc));
      }
    } //else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);

    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    // //console.log("getCollectionSearchByPrefix() => conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  getCollectionSearchByComplexFilter = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both
  ) => {
    //console.log(
    //   "getCollectionSearchByPrefix(collectionName:",
    //   collectionName,
    //   "queryTarget:",
    //   queryTarget,
    //   "queryText:",
    //   queryText,
    //   "firstVisible:",
    //   firstVisible,
    //   "lastVisible:",
    //   lastVisible,
    //   "pageSize:",
    //   pageSize,
    //   "orderby:",
    //   orderby,
    //   "ascDesc:",
    //   ascDesc,
    //   "vinculo_idEntidade:",
    //   vinculo_idEntidade,
    //   "vinculoTarget:",
    //   vinculoTarget,
    //   ")"
    // );
    if (lastVisible === undefined) lastVisible = null;
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = [];
    if (vinculo_idEntidade) {
      if (vinculo_idEntidade !== null) {
        if (vinculoTarget) {
          if (vinculoTarget !== null) conditions.push(where(vinculoTarget, "==", vinculo_idEntidade));
          else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
        } else conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
      }
    }
    // conditions = [...conditions, ...this.getConditionsFromQueryFilter(queryTarget, queryText)];
    //interar para cada propriedade de querytext

    if (queryText) {
      if (queryText !== "") {
        if (queryText.searchInfo && queryText.searchInfo !== "")
          conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText.searchInfo)]));
        let queryTextArray = Object.entries(queryText);
        queryTextArray.map((item) => {
          if (!["", "Todos", "Todas", "Selecione"].includes(item[1])) conditions.push(where(item[0], "==", item[1]));
        });
      }
    }

    // if (queryText !== "") conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]));
    if (!showAllVersions) conditions.push(where("metadados.successorId", "==", ""));
    // //console.log("getCollectionSearchByComplexFilter() => show_1_Active_2_Inactive_3_Both=", show_1_Active_2_Inactive_3_Both);
    if (show_1_Active_2_Inactive_3_Both === 1) conditions.push(where("metadados.active", "==", true));
    else if (show_1_Active_2_Inactive_3_Both === 2) conditions.push(where("metadados.active", "==", false));
    // if (!showAllVersions) conditions.push(where("metadados.active", "==", true));
    if (pageSize !== null) {
      if (firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
      else conditions.push(limit(pageSize));
    }
    // //console.log("getCollectionSearchByComplexFilter() => queryTarget=", queryTarget, "queryText=", queryText);
    if (queryTarget !== orderby && queryText !== "") conditions.push(orderBy(queryTarget, ascDesc));
    // //console.log("getCollectionSearchByComplexFilter() => orderby=", orderby);
    if (orderby !== "") {
      if (Array.isArray(orderby)) orderby.map((item) => conditions.push(orderBy(item)));
      else conditions.push(orderBy(orderby, ascDesc));
    } else conditions.push(orderBy(orderby, ascDesc));
    conditions = keepOrderIfNotDuplicated(conditions);

    if (firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    if (lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    //console.log("getCollectionSearchByComplexFilter() => conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    //console.log("getCollectionSearchByComplexFilter() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSizeByComplexFilter(
      collectionName,
      queryTarget,
      queryText,
      firstVisible,
      lastVisible,
      pageSize,
      orderby,
      ascDesc,
      showAllVersions,
      vinculo_idEntidade,
      vinculoTarget
    );
    // //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getPrevPage = async (
    collectionName,
    queryTarget,
    queryText,
    firstVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    // //console.log(
    //   "collectionName=",
    //   collectionName,
    //   " queryTarget=",
    //   queryTarget,
    //   " queryText=",
    //   queryText,
    //   " firstVisible=",
    //   firstVisible,
    //   " pageSize=",
    //   pageSize,
    //   " orderby=",
    //   orderby,
    //   " ascDesc=",
    //   ascDesc,
    //   "showAllVersions=",
    //   showAllVersions,
    //   " vinculo_idEntidade=",
    //   vinculo_idEntidade
    // );
    if (typeof queryText === "string") {
      return this.getCollectionSearchByPrefix(
        collectionName,
        queryTarget,
        queryText,
        firstVisible,
        null,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget
      );
    } else {
      return this.getCollectionSearchByComplexFilter(
        collectionName,
        queryTarget,
        queryText,
        firstVisible,
        null,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget
      );
    }
  };

  getNextPage = async (
    collectionName,
    queryTarget,
    queryText,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget
  ) => {
    //console.log(
    //   "collectionName=",
    //   collectionName,
    //   " queryTarget=",
    //   queryTarget,
    //   " queryText=",
    //   queryText,
    //   " lastVisible=",
    //   lastVisible,
    //   " pageSize=",
    //   pageSize,
    //   " orderby=",
    //   orderby,
    //   " ascDesc=",
    //   ascDesc,
    //   "showAllVersions=",
    //   showAllVersions,
    //   " vinculo_idEntidade=",
    //   vinculo_idEntidade,
    //   " vinculoTarget=",
    //   vinculoTarget
    // );
    if (typeof queryText === "string") {
      return this.getCollectionSearchByPrefix(
        collectionName,
        queryTarget,
        queryText,
        null,
        lastVisible,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget
      );
    } else {
      return this.getCollectionSearchByComplexFilter(
        collectionName,
        queryTarget,
        queryText,
        null,
        lastVisible,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget
      );
    }
  };

  getMorePage = async (
    collectionName,
    queryTarget,
    queryText,
    lastVisible,
    pageSize,
    orderby,
    ascDesc,
    showAllVersions,
    vinculo_idEntidade,
    vinculoTarget,
    show_1_Active_2_Inactive_3_Both
  ) => {
    // //console.log(
    //   "collectionName=",
    //   collectionName,
    //   " queryTarget=",
    //   queryTarget,
    //   " queryText=",
    //   queryText,
    //   " lastVisible=",
    //   lastVisible,
    //   " pageSize=",
    //   pageSize,
    //   " orderby=",
    //   orderby,
    //   " ascDesc=",
    //   ascDesc,
    //   "showAllVersions=",
    //   showAllVersions,
    //   " vinculo_idEntidade=",
    //   vinculo_idEntidade,
    //   " vinculoTarget=",
    //   vinculoTarget
    // );

    // if (queryText.substring(0, 1) === "%" || queryText.substring(0, 1) === "*" || queryText.substring(0, 1) === "?") {
    //   return this.getCollectionSearchByPartialWithoutSize(
    //     collectionName,
    //     queryTarget,
    //     queryText.substring(1, queryText.length),
    //     null,
    //     lastVisible,
    //     pageSize,
    //     orderby,
    //     ascDesc,
    //     showAllVersions,
    //     vinculo_idEntidade,
    //     vinculoTarget
    //   );
    // } else {
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    if (typeof queryText === "string") {
      return this.getCollectionSearchByPrefixWithoutSize(
        collectionName,
        queryTarget,
        queryText,
        null,
        lastVisible,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget,
        show_1_Active_2_Inactive_3_Both
      );
    } else {
      return this.getCollectionSearchByComplexFilter(
        collectionName,
        queryTarget,
        queryText,
        null,
        lastVisible,
        pageSize,
        orderby,
        ascDesc,
        showAllVersions,
        vinculo_idEntidade,
        vinculoTarget,
        show_1_Active_2_Inactive_3_Both
      );
    }
    // }
  };

  getConditionsFromQueryFilter = (queryTarget, queryFilter) => {
    let conditions = [];
    // let searchInfoText = "";
    // //console.log("getConditionsFromQueryFilter => queryTarget=", queryTarget, "queryFilter=", queryFilter);
    if (queryFilter !== undefined) {
      if (typeof queryFilter === "object") {
        if (queryFilter.situacaoSearch !== "Todas") conditions.push(where("situacao", "==", queryFilter.situacaoSearch));
        if (queryFilter.searchInfo)
          conditions = [...conditions, ...this.getWhereQyeryConditionsFromQueryFilter(queryTarget, queryFilter.searchInfo)];
        if (queryFilter.filiacao)
          conditions = [...conditions, where("filiacao", "==", queryFilter.filiacao)];
        if (queryFilter.vinculadoAEntidadeID)
          conditions = [...conditions, where("vinculadoAEntidadeID", "==", queryFilter.vinculadoAEntidadeID)];
      } else if (typeof queryFilter === "string") {
        conditions = [...conditions, ...this.getWhereQyeryConditionsFromQueryFilter(queryTarget, queryFilter)];
      }
    }
    // //console.log("getConditionsFromQueryFilter => conditions=", conditions);
    return conditions;
  };

  getConditionsFromQueryFilter_version2 = (queryTarget, queryFilter) => {
    let conditions = [];
    // let searchInfoText = "";
    // console.log("getConditionsFromQueryFilter_version2 => queryTarget=", queryTarget, "queryFilter=", queryFilter);
    if (queryFilter !== undefined) {
      if (typeof queryFilter === "object") {
        if (queryFilter.situacaoSearch && queryFilter.situacaoSearch !== "Todas") conditions.push(where("situacao", "==", queryFilter.situacaoSearch));
        if (queryFilter.searchInfo)
          conditions = [...conditions, ...this.getWhereQyeryConditionsFromQueryFilter(queryTarget, queryFilter.searchInfo)];
        if (queryFilter.filiacao) {
          conditions = [...conditions, where("filiacao", "==", queryFilter.filiacao)];
          if (queryFilter.vinculadoAEntidadeID)
            conditions = [...conditions, where("vinculadoAEntidadeID", "==", queryFilter.vinculadoAEntidadeID)];
        }
        else if (queryFilter.federacao) {
          conditions = [...conditions, where("dadosFiliacao.federacao", "==", queryFilter.federacao)];
          if (queryFilter.vinculadoAEntidadeID)
            conditions = [...conditions, where("dadosFiliacao.vinculadoAEntidadeID", "==", queryFilter.vinculadoAEntidadeID)];
        }
      } else if (typeof queryFilter === "string") {
        conditions = [...conditions, ...this.getWhereQyeryConditionsFromQueryFilter(queryTarget, queryFilter)];
      }
    }
    // console.log("getConditionsFromQueryFilter => conditions=", conditions);
    return conditions;
  };

  getWhereQyeryConditionsFromQueryFilter = (queryTarget, queryFilter, collectionName) => {
    const conditions = [];
    if (queryFilter.length > 0) {
      queryFilter = queryFilter.trim().toLowerCase().replaceAll("  ", " ");
      if (["%", "*", "?"].includes(queryFilter.substring(0, 1))) {
        queryFilter = queryFilter.substring(1, queryFilter.length);
        conditions.push(where(queryTarget, "array-contains-any", [...queryFilter.split(" ").slice(0, 10)]));
        if (queryFilter.searchInfo) {
          if (queryFilter.searchInfo.length > 0) conditions.push(orderBy(queryTarget, "asc"));
        }
      } else {
        queryTarget = queryTarget.replace("trigam", "searchableName").replace("trigam", "name");
        conditions.push(where(queryTarget, ">=", queryFilter));
        conditions.push(where(queryTarget, "<=", queryFilter + "\uf8ff"));
        //O orderBy abaixo é necessário para que o startAfter e o endBefore funcionem corretamente
        conditions.push(orderBy(queryTarget, "asc"));
      }
    }
    return conditions;
  };

  getCollectionByConditions = async (
    collectionName,
    conditions
  ) => {
    // console.log("getCollectionByConditions() => collectionName=", collectionName, "conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions))
      .catch((error) => {
        console.error("Error getting document:", error);
        throw error;
      });
    // console.log("getCollectionByConditions() => querySnapshot=", querySnapshot);
    let collectionSize = await this.getCollectionSizeByConditions(
      collectionName,
      conditions
    )
      .catch((error) => {
        console.error("Error getting document:", error);
        throw error;
      });
    // //console.log("getCollectionSearchByPrefix() => collectionSize=", collectionSize);
    return [querySnapshot.docs, collectionSize];
  };

  getCollectionSizeByConditions = async (
    collectionName,
    conditions
  ) => {
    try {
      // console.log("getCollectionSizeByConditions() => collectionName=", collectionName, "conditions=", conditions);
      let snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions))
        .then((snapshot) => {
          // console.log("getCollectionSizeByConditions() => snapshot=", snapshot);
          return snapshot;
        })
        .catch((error) => {
          console.error("Error getting document:", error);
          throw error;
        });
      // console.log("getCollectionSizeByConditions() => snapshot=", snapshot);
      return snapshot.data().count;

    } catch (error) {
      console.error("Error getting document:", error);
      throw error;
    }
  };


}

export default new GenericVinculadoService();
